import React                    from 'react'
import { Container }            from 'react-bootstrap'
import { Helmet }               from 'react-helmet'
import { useParams }            from 'react-router-dom'
// import { HashLink }          from 'react-router-hash-link'

import Header                   from '../components/Header'
import Footer                   from '../components/Footer'

import './PartnersByCountyScreen.css'

const PartnersByCountyScreen = (props) => {

    const { country, county } = useParams();

    return (
    <>
        <Helmet>
            <title>UK Accounting Practices Directory</title>
            <meta name="description" content="" />
        </Helmet>

        <Header />

        <Container id="PartnersByCountyScreen" className="copy">

            <h3>Our Partners in {county}, {country}</h3>

            <p>
                todo: list partner cards out..
            </p>

        </Container>

        <Footer />
    </>
    )
}

export default PartnersByCountyScreen
