import React                    from 'react'
import { Container, Row, Col }  from 'react-bootstrap'
import { Helmet }               from 'react-helmet'
import { HashLink }             from 'react-router-hash-link'

import Header               from '../components/Header'
import Footer               from '../components/Footer'
import Advert               from '../components/Advert'
import CookieBanner         from '../components/CookieBanner'
import Calculator           from '../components/Calculator'
import TransactionHistory   from '../components/TransactionHistory'
import FlatRateVATTable     from '../components/FlatRateVATTable'

import './CalculatorScreen.css'


const CalculatorScreen = () => {

    return (
    <>
        <Helmet>
            <title>UK VAT Calculator - CalcVAT.co.uk</title>
            <meta name="description" content="Use our UK VAT Calculator to effortlessly compute VAT. Ideal for businesses and individuals managing UK tax calculations. Fast, accurate, and easy to use." />
        </Helmet>

        <CookieBanner />

        <Header />

        <Container id="CalculatorScreen" className="main copy">

        <section id="calcvat-vat-calculator">
            <Row>
                <Col>
                    <section id="heading">
                        <h1 className="display-4 fw-normal">UK VAT Calculator</h1>

                        <p>Related articles:</p>
                        <ul>
                            <li><HashLink smooth to="/what-is-vat">What is VAT</HashLink></li>
                            <li><HashLink smooth to="/current-vat-rate">Current Rate of VAT</HashLink></li>
                            <li><HashLink smooth to="/flat-rate-vat">The Flat Rate VAT Scheme</HashLink></li>
                        </ul>
                    </section>
                </Col>
            </Row>

            <Row>
                <Col xs={2} md={2}>
                    <Advert />
                </Col>

                <Col>
                    <Calculator />
                </Col>

                <Col>
                    <TransactionHistory />
                </Col>

                <Col xs={2} md={2}>
                    <Advert />
                </Col>
            </Row>
        </section>

        <Row><Col style={{height: '4em'}}></Col></Row>

        <Row>
            <Col>
                <h2>Flat VAT Rates per Business Category</h2>
                <p>
                The following table shows the UK VAT percentage rate for different business types.
                Note that to qualify for these reduced flat VAT rates, your business _must_ be a
                registered participant in the <HashLink smooth to="/flat-rate-vat#FlatRateVATScreen">HMRC&apos;s flat rate VAT scheme</HashLink>.
                </p>
                <FlatRateVATTable />
            </Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        </Container>

        <Footer />
    </>
    )
}

export default CalculatorScreen
