import React, { useState, useEffect } from 'react'
import { Button, Table } from 'react-bootstrap'
import { CSVLink } from 'react-csv';

import useStore from "../state/GlobalState"

import './TransactionHistory.css'

const TransactionHistory = () => {

    const data      = useStore((state) => state.data);
    const clearData = useStore((state) => state.clear);

    const [displayData, setDisplayData] = useState([]);

    const [itemsPerPg]              = useState(4);
    const [currentPg, setCurrentPg] = useState(1);
    const [totalPgs, setTotalPgs]   = useState(0);

    const prevLabel = "<";
    const nextLabel = ">";

    const resetData = () => {
      setCurrentPg(1);
      setTotalPgs(0);
      clearData();
    };

    useEffect (() => {
      const startIndex  = (currentPg - 1) * itemsPerPg;
      const endIndex    = startIndex+itemsPerPg;

      setTotalPgs(parseInt(data.length / itemsPerPg) + ((data.length % itemsPerPg) > 0 ? 1:0));
      setDisplayData(data.slice(startIndex, endIndex));
    }, [data, itemsPerPg, currentPg, totalPgs]);

    return (
    <>
    <div id="transactionHistoryTable">
    <Table id="tblTransactionHistory" striped bordered hover width="100%" className="table table-sm">

    <thead>
      <tr className="table-primary">
        <th colSpan="4"><h3>Transaction History</h3></th>
      </tr>

      <tr>
        <th colSpan="4">
            <CSVLink className="export-button" data={data} filename="transaction_history.csv">Download</CSVLink>
            <Button onClick={resetData} >Clear</Button>
        </th>
      </tr>

      <tr>
        <th>Amount</th>
        <th>VAT Rate (%)</th>
        <th>VAT Amount</th>
        <th>Amount + VAT</th>
      </tr>
    </thead>

    <tbody>
      {
        displayData.map((i, index) => (
          <tr key={index}>
            <td>{ parseFloat(i.amount).toFixed(2) }</td>
            <td>{ parseFloat(i.rate).toFixed(2) }</td>
            <td>{ parseFloat(i.vat).toFixed(2) }</td>
            <td>{ parseFloat(i.total).toFixed(2) }</td>
          </tr>
        ))
      }

      <tr>
          <td colSpan="3">pg {totalPgs === 0 ? 0 : currentPg} of {totalPgs}</td>
          <td>
            <button onClick={ () => { if (currentPg > 1 && totalPgs > 1) { setCurrentPg(currentPg -1)} } }> {prevLabel} </button>
            &nbsp;
            <button onClick={ () => { if (currentPg < totalPgs ) { setCurrentPg(currentPg+1) } } } > {nextLabel} </button>
          </td>
      </tr>
    </tbody>

    </Table>
    </div>
    </>
    )
}

export default TransactionHistory
