import React, { useState, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import VatRateCtrl          from './VatRateCtrl';
import VatToggleCtrl        from './VatToggleCtrl'

import useStore      from "../state/GlobalState"

import './Calculator.css'


const Calculator = () => {

  // global state
  const vatRate         = parseFloat(useStore((state) => state.vatRate));
  const addTransaction  = useStore((state) => state.add);
  const vatToggle       = useStore((state) => state.vatToggle);


  // local state
  const inputRef                 = useRef(null);
  const [input, setInput] 	     = useState('');
  const [vatOnly, setVatOnly]    = useState('');
  const [result, setResult]      = useState('');

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent) ? true : false;
  }

  const handleClick = (event) => {
    const value = event.target.value;
    setInput(input + value);
  }

  const handleChange = (event) => {
    const value = event.target.value;

    if (/^\d*\.?\d*$/.test(value))
      setInput(value);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter')
        calculate();

    if (event.key === 'Backspace')
        clearEntry();
  }

  const calculate = () => {

    // prevent user from spamming the CalcVAT button
    if (input === '')
    {
        if (! isMobile())
            inputRef.current.focus();

        return;
    }

    const amount        = parseFloat(input);
    const rate          = parseFloat(vatRate);
    let vat             = undefined;
    let amountPlusVat   = undefined;
    vat                 = vatToggle ? parseFloat((rate/100.0) * amount).toFixed(2) : ((rate / (100.0+rate)) * amount * -1.0).toFixed(2);
    amountPlusVat       = parseFloat(parseFloat(amount) + parseFloat(vat)).toFixed(2);

    setVatOnly(vat);
    setResult(amountPlusVat);

    const newData       = {"amount": amount, "rate": vatRate, "vat": vat, "total": amountPlusVat};
    addTransaction(newData); // this add function is part of state, so we're modifying state here :)

    if (! isMobile())
        inputRef.current.focus();
  }

  const clearEntry = () => {
    setInput('');
    setVatOnly('');
    setResult('');

    if (! isMobile())
        inputRef.current.focus();
  }

  return (
    <>

    <div id="calculator">
        <Row>
            <Col>
                <input
                    id="userInput"
                    ref={inputRef}
                    type="text"
                    pattern="[0-9]*"
                    value={input}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Amount"
                />
            </Col>
        </Row>

        <Row>
            <Col>
                <input
                    id="vatOnlyInput"
                    type="text"
                    value={vatOnly}
                    placeholder="VAT Amount"
                    readOnly
                />
            </Col>
        </Row>

        <Row>
            <Col>
                <input
                    id="resultInput"
                    type="text"
                    value={result}
                    placeholder={ vatToggle ? "Amount + VAT" : "Amount - VAT" }
                    readOnly
                />
            </Col>
        </Row>

        <Row xs={2} lg={2}>

            <Col>
                <VatRateCtrl />
            </Col>

            <Col>
                <VatToggleCtrl enabledLabel={"Apply VAT"} disabledLabel={"Remove VAT"} />
            </Col>
        </Row>

        <Row xs={3}>
            <Col><Button value="7" onClick={handleClick}>7</Button></Col>
            <Col><Button value="8" onClick={handleClick}>8</Button></Col>
            <Col><Button value="9" onClick={handleClick}>9</Button></Col>
        </Row>

        <Row xs={3}>
            <Col><Button value="4" onClick={handleClick}>4</Button></Col>
            <Col><Button value="5" onClick={handleClick}>5</Button></Col>
            <Col><Button value="6" onClick={handleClick}>6</Button></Col>
        </Row>

        <Row xs={3}>
            <Col><Button value="1" onClick={handleClick}>1</Button></Col>
            <Col><Button value="2" onClick={handleClick}>2</Button></Col>
            <Col><Button value="3" onClick={handleClick}>3</Button></Col>
        </Row>

        <Row xs={3}>
            <Col></Col>
            <Col><Button value="0" onClick={handleClick}>0</Button></Col>
            <Col><Button value="." onClick={handleClick}>.</Button></Col>
        </Row>

        <Row xs={3}>
            <Col></Col>
            <Col><Button id="btnClear" onClick={clearEntry}>CE</Button></Col>
            <Col><Button id="btnCalc"  onClick={calculate}>=</Button></Col>
        </Row>
    </div>
    </>
  );
}

export default Calculator;
