import React from 'react'
import { Container, Row, Col }  from 'react-bootstrap'
import { Helmet }               from 'react-helmet'

import CookieBanner         from '../components/CookieBanner'
import Header               from '../components/Header'
import Footer               from '../components/Footer'
import Advert               from '../components/Advert'

import './AboutUsScreen.css'


const AboutUsScreen = () => {

    const imagesDir = `/img`

    return (
    <>
        <Helmet>
            <title>About Us</title>
            <meta name="description" content="Learn more about calcvat.co.uk, the UK's trusted VAT calculator. Discover our commitment to accuracy, and how we make VAT calculations seamless for businesses and individuals alike." />
        </Helmet>

        <CookieBanner />

        <Header />
        <main className="py-3">

        <Container id="AboutUsScreen" className="copy">

        <Row>
            <Col>
                <Advert />
            </Col>

            <Col xs={8}>

                <section id="heading">
                    <h1 className="display-4 fw-normal">Meet the Team</h1>

                    <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                        <img src={`${imagesDir}/meet-the-team.jpg`} alt="meet the team!" />
                    </p>
                </section>

                <section id="origin-story">
                    <h2>The CalcVAT Origin Story</h2>
                    <p>
                    CalcVAT started off with a conversation along the lines of
                    </p>

                    <p className="quotation">
                        &quot;Wouldn&apos;t it be great if there was a website that...&quot;
                    </p>

                    <p>
                    The idea soon took shape and turned into reality when John wrote the
                    bones of the CalcVAT VAT Calculator tool on a wintry Saturday afternoon.  Having recognised that just
                    creating a VAT Calculator simply wouldn&apos;t do, they set about making content that both busy business owners
                    and accounting professionals would find helpful.
                    </p>

                    <p>
                    So, we&apos;re focusing on creating content that&apos;ll be helpful for new businesses.  We hope you find it to be a useful
                    resource and we&apos;d love to hear your feedback.
                    </p>
                </section>

                <section id="about-john">
                    <Container id="the-lapin">
                        <Row>
                            <Col className="col-3">
                                <img className="circular" src="img/lappy.jpg" width="200" alt="john developer" />
                            </Col>

                            <Col>
                                <h2>John McCullough</h2>
                                <h3>Developer, DramaLlama Limited</h3>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                            <p>
                            John has had a passion for computing and computer programming from a young age and has spent more years
                            than he cares to admit behind the keyboard.
                            </p>

                            <p>
                            He spent many years in corporate software development, having earned a bachelor&apos;s degree in
                            Computing Science and masters in Games Programming, before forming Dramallama Limited, a company
                            specialising in creating engaging websites and their media content for today&apos;s Internet.
                            </p>

                            <p>
                            John has indulged many pastimes past and present, including playing chess, motorcycling and skydiving,
                            and has written a book on Cryptocurrency.
                            </p>

                            <p>
                            John spends his working hours building and maintaining websites and listening
                            to <a href="https://www.youtube.com/watch?v=jht3kHTwj8s">ambient sci-fi music</a> and&nbsp;
                            tending to the needs of various animals.
                            </p>

                            <p>He lives in the Co. Antrim countryside with his wife Sharon, their daughter and several animals.</p>

                            </Col>
                        </Row>
                    </Container>
                </section>

                <section id="about-sharon">
                    <Container id="the-shazzle">
                    <Row>
                        <Col className="col-3">
                            <img id="shazzle-pic" className="circular" src="img/moonkesh.jpg" width="200" alt="sharon accountant ACCA FIATA" />
                        </Col>

                        <Col>
                            <h2>
                            Sharon Gillespie &nbsp;
                            <a href="https://www.linkedin.com/in/sharon-gillespie">
                                <i className="bi bi-linkedin"></i>
                            </a>
                            </h2>
                            <h3>Accountant, Company Director, Project Contributor & Advisor for CalcVAT</h3>
                        </Col>
                    </Row>
                    </Container>


                    <p>
                    Sharon has run a successful Accounting practice in Northern Ireland for more than thirty years,
                    having worked alongside her father who founded the company.  Having earned her bachelors degree in Accounting,
                    Sharon is also a fellow of both the Association of Chartered Certified Accountants and the Institute of
                    Accounting Technicians Ireland.
                    </p>

                    <p>
                    Sharon has built a business whose services are sought after for their
                    approachable manner and helpful advice, an approach to customer care that Sharon
                    calls <i><a href="https://revelationaccountants.com/about">Going Beyond the Numbers</a></i>.
                    </p>

                    <p>
                    In her spare time, Sharon is equally busy looking after their many pets.  She enjoys socialising with
                    friends, travelling to hot places and swimming with husband John and their daughter.
                    </p>
                </section>

            </Col>

            <Col>
                <Advert />
            </Col>
        </Row>

        </Container>
        </main>
        <Footer />
    </>
    )
}

export default AboutUsScreen
