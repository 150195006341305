import React                    from 'react'
import { Container }            from 'react-bootstrap'
import { Helmet }               from 'react-helmet'
// import { Link }              from 'react-router-dom'
// import { HashLink }          from 'react-router-hash-link'

import Header                   from '../components/Header'
import Footer                   from '../components/Footer'
import PartnerIndex             from '../components/PartnerIndex'

import './PartnerIndexScreen.css'


const PartnerIndexScreen = () => {

    return (
    <>
        <Helmet>
            <title>Partner Index of UK Accounting Practices</title>
            <meta name="description" content="" />
        </Helmet>

        <Header />

        <Container id="PartnerIndexScreen" className="copy">

            <h1>Partner Index of UK Accounting Practices</h1>
            <p>
            In aiming to bring the public a useful, searchable resource, the following is an index of UK accounting partner practices, broken down by geographic location.
            </p>
            <PartnerIndex />
        </Container>

        <Footer />
    </>
    )
}

export default PartnerIndexScreen
