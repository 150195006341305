import React from 'react'

import { Container, Navbar }    from 'react-bootstrap';
import { HashLink }             from 'react-router-hash-link'

import Advert from './Advert'

import './Header.css'


const Header = () => {

    return (
    <>
    <header id="header">
        <nav className="navbar navbar-expand-lg text-dark bg-white navbar-light sticky-top p-0 px-4 px-lg-5">

            <Navbar.Brand href="/">
                <h2 className="text-primary mb-4">
                  <i className="bi bi-calculator"></i>
                  CalcVAT.co.uk
                </h2>
            </Navbar.Brand>

            <ul>
                <li> <a href="/">Home</a> </li>
                <li> <HashLink smooth to="/vat-calculator">VAT Calculator</HashLink> </li>
                <li> <HashLink smooth to="/articles">Articles</HashLink> </li>
                <li> <HashLink smooth to="/about-us">About</HashLink> </li>
                <li> <HashLink smooth to="/contact-us">Contact</HashLink> </li>
            </ul>

        </nav>

    </header>

    <Container>
        <Advert />
    </Container>

    </>
    )
}

export default Header
