import React from 'react'


const Advert = () => {

    const adAgency = process.env.REACT_APP_AD_AGENCY || "adsense";


    if (adAgency === 'adsense')
    {
        return (
            <></>
        )

        // const theStyle = { display: "block" };
        // return (
        // <>
        // <div className="display-ad">
        //     <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3241925687268776" crossOrigin="anonymous"></script>

        //     <ins className="adsbygoogle"
        //          style={{ theStyle }}
        //          data-ad-client="ca-pub-3241925687268776"
        //          data-ad-slot="9285316706"
        //          data-ad-format="auto"
        //          data-full-width-responsive="true"></ins>

        //     <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        // </div>
        // </>
        // )
    }
    else if (adAgency === 'ezoic')
    {
        const validIds = [
            'ezoic-pub-ad-placeholder-101',
            'ezoic-pub-ad-placeholder-102',
            'ezoic-pub-ad-placeholder-103',
            'ezoic-pub-ad-placeholder-104'
        ];

        const randomId = validIds[Math.floor(Math.random() * validIds.length)];

        return(
            <>
            <div className="display-ad">
                <div id={randomId}> </div>
            </div>
            </>
        )
    }
    else
    {
        return (<></>)
    }
}

export default Advert
