import React from 'react'
import { Link }                 from 'react-router-dom'
//import { HashLink }             from 'react-router-hash-link'

import './ArticleLinks.css'

const ArticleLinks = () => {
    return (
    <div id="ArticleLinks">

    <h2>General</h2>
    <ul>
        <li><Link to="/what-is-vat">Understanding UK VAT: An Introduction</Link></li>
        <li><Link to="/current-vat-rate">A Guide to UK VAT Rates: Standard, Reduced, and Zero</Link></li>
        <li><Link to="/uk-vat-formula">How to Calculate VAT in the UK: A Step-by-Step Guide</Link></li>
    </ul>

    <h2>Frequently Asked Questions</h2>
    <ul>
        <li><Link to="/register-for-vat-uk">How to Register for VAT in the UK</Link></li>
        <li><Link to="/submit-your-vat-return">How to Submit your VAT Return</Link></li>
        <li><Link to="/reclaim-vat">How to Reclaim VAT on Business Expenses</Link></li>
        <li><Link to="/issuing-vat-invoices">How to Issue a VAT Invoice</Link></li>
    </ul>

    <h2>HMRC VAT Schemes</h2>
    <ul>
        <li><Link to="/flat-rate-vat">About the Flat Rate VAT Scheme</Link></li>
        <li><Link to="/annual-accounting-vat-scheme">The Annual Accounting VAT Scheme</Link></li>
        <li><Link to="/retail-vat-schemes">The UK&apos;s Retail VAT Schemes: A Comprehensive Guide for Retailers</Link></li>
    </ul>

    </div>
    )
}

export default ArticleLinks
