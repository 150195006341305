import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'

import './GettingStarted.css'

const GettingStarted = () => {
    return (
    <>
        <section id="getting-started">
            <h2>Getting Started with VAT Calculator</h2>

            <Row>
                <Col>
                <Card className="bg-light">
                    <Card.Title>
                    <i className="bi bi-alarm"></i>
                    <h3>To begin</h3>
                    </Card.Title>
                    <Card.Body>Just tap a value into the calculator, then tap the green Calc VAT button</Card.Body>
                </Card>
                </Col>

                <Col>
                <Card className="bg-light">
                    <Card.Title>
                    <i className="bi bi-toggles2"></i>
                    <h3>Apply or Remove VAT</h3>
                    </Card.Title>
                    <Card.Body>Use the switch control to change between add and remove VAT</Card.Body>
                </Card>
                </Col>

                <Col>
                <Card className="bg-light">
                    <Card.Title>
                        <i className="bi bi-keyboard"></i>
                        <h3>Controls</h3>
                    </Card.Title>
                    <Card.Body>Use your mouse or numeric keyboard to input numbers, use backspace to clear</Card.Body>
                </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                <Card className="bg-light">
                    <Card.Title>
                    <i className="bi bi-cup-hot"></i>
                    <h3>UK VAT Rates</h3>
                    </Card.Title>
                    <Card.Body>Standard rate is 20%. Some UK goods & services are 5% VAT, some 0%.</Card.Body>
                </Card>
                </Col>

                <Col>
                <Card className="bg-light">
                    <Card.Title>
                    <i className="bi bi-truck-flatbed"></i>
                    <h3>Flat Rate</h3>
                    </Card.Title>
                    <Card.Body>If you&apos;re registered for a scheme, your business might be on a flat rate</Card.Body>
                </Card>
                </Col>

                <Col>
                <Card className="bg-light">
                    <Card.Title>
                    <i className="bi bi-clock-history"></i>
                    <h3>Transaction History</h3>
                    </Card.Title>
                    <Card.Body>Transaction history control enables you to clear or download your entries at any time</Card.Body>                </Card>
                </Col>
            </Row>
        </section>
    </>
    )
}

export default GettingStarted
