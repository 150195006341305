import React                    from 'react'
import { Container, Row, Col }  from 'react-bootstrap'
import { Helmet }               from 'react-helmet'
//import { Link }                 from 'react-router-dom'
import { HashLink }             from 'react-router-hash-link'

import Header       from '../components/Header'
import Footer       from '../components/Footer'
import Advert       from '../components/Advert'
import ArticleLinks from '../components/ArticleLinks'

import './SiteIndexScreen.css'



const SiteIndexScreen = () => {

    const imageDir = `/img`


    return (
    <>
        <Helmet>
            <title>Site Index</title>
            <meta name="description" content="Our Site Index is the human readable form of the XML sitemap, so far as is practical." />
        </Helmet>

        <Header />

        <Container id="SiteIndexScreen" className="copy">

        <Row>
            <Col><Advert /></Col>

            <Col xs={8}>
                <h1 className="display-4 fw-normal">Site Index for CalcVAT.co.uk</h1>

                <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                    <img src={`${imageDir}/sitemap.jpg`} alt="Sitemap for CalcVAT.co.uk" />
                </p>

                <Row>

                <Col>
                    <h2>Utilities</h2>
                    <p>
                        <div><HashLink smooth to="/vat-calculator#">VAT Calculator</HashLink></div>
                    </p>

                    <Row><Col style={{height: '2em'}}></Col></Row>

                    <h2>Value-Added Tax Articles</h2>
                    <ArticleLinks />

                </Col>
                </Row>

            </Col>

            <Col><Advert /></Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        <Row>
            <Col>
                <div className="display-ad">
                    <Advert />
                </div>
            </Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        </Container>
        <Footer />
    </>
    )
}

export default SiteIndexScreen
