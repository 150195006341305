import React from 'react'

import './Basic404Screen.css'

const Basic404Screen = () => {
    return (
    <>
    <div class="Container" id="Basic404Screen">
      <div class="notfound-404">
        <h3>your request returned http status code</h3>
        <h1>404</h1>
        <h2>sorry, the resource you requested was not found</h2>
      </div>
    </div>
    </>
    )
}

export default Basic404Screen
