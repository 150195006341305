import React, { useEffect, useState } from 'react'
import  { Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'

import './ArticlesCarousel.css'


const ArticlesCarousel = () => {

    const [articleData, setArticleData] = useState([]);     // total data set
    const [displayData, setDisplayData] = useState([]);     // data subset, shown in paginated list
    const [queryError, setQueryError] = useState(null);

    const [itemsPerPg]              = useState(4);
    const [currentPg, setCurrentPg] = useState(1);
    const [totalPgs, setTotalPgs]   = useState(0);

    const prevLabel = "<";
    const nextLabel = ">";

    useEffect (() => {
        
        axios.get('/data/ArticleSnippets.json')
            .then(response => setArticleData(response.data))
            .catch(err => {
                console.log(err);
                setQueryError("Unable to retrieve article snippets");
            });

        const startIndex  = (currentPg - 1) * itemsPerPg;
        const endIndex    = startIndex+itemsPerPg;

        setTotalPgs(parseInt(articleData.length / itemsPerPg) + ((articleData.length % itemsPerPg) > 0 ? 1:0));
        setDisplayData(articleData.slice(startIndex, endIndex));

    }, [articleData, itemsPerPg, currentPg, totalPgs]);

    return (
    <>
        {
            queryError &&
            <>
                <div id="ErrorMsg" className="Error">{ queryError }</div>
            </>
        }

        <div id="ArticleCards">
        {
            displayData.map((i, idx) => {
                return (
                <Container key={idx}>
                    <Row>
                        <Col sm={2} lg={4}>
                            <img src={i.img} alt={i.title} />
                        </Col>

                        <Col sm={4} lg={8}>
                            <h3>{ <Link to={i.articleLink}>{i.title}</Link> }</h3>
                            <p>{ i.publishedAt }</p>
                            <div className="ArticleSnippet" dangerouslySetInnerHTML={{ __html: i.snippetHtml }}></div>
                        </Col>
                    </Row>
                </Container>
                )
            })
        }
        </div>

        {

        totalPgs > 1 &&

        <div id="ArticleNavigation">
            <span>pg {totalPgs === 0 ? 0 : currentPg} of {totalPgs}</span>
            <span>
                <button onClick={ () => { if (currentPg > 1 && totalPgs > 1) { setCurrentPg(currentPg -1)} } }> { prevLabel } </button>
                &nbsp;
                <button onClick={ () => { if (currentPg < totalPgs ) { setCurrentPg(currentPg+1) } } } > { nextLabel } </button>
            </span>
        </div>

        }
    </>
    )
}

export default ArticlesCarousel
