
import { create } from 'zustand'

// see the following for a refresher on state mgmt with zustand
// https://github.com/pmndrs/zustand


// global state for:
// - the calculator's transaction history (data)
// - the vatRate value, which is user changeable
const useStore = create((set) => ({

  data:  [],
  add:   (i) => set(state => ({ data: [...state.data, i] })),
  clear: () => set(state => ({ data: [] })),

  vatRate: 20.0,
  setVatRate: (r) => set(state => ({ vatRate: r })),

  vatToggle: true,
  setVatToggle: (r) => set(state => ({vatToggle: r})),

  siteTitle: "CalcVAT Flat Rate VAT Calculator",
  setSiteTitle: (r) => set(state => ({siteTitle: r}))

}));


export default useStore
