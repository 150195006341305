import React from 'react'

import { Container, Row, Col }  from 'react-bootstrap'
// import { Link }                 from 'react-router-dom'
//import { HashLink }             from 'react-router-hash-link'
// import { TikTok }               from 'react-tiktok'
// <TikTok url={ tiktokUrl } />

import { Helmet }           from 'react-helmet'
import Header               from '../components/Header'
import Footer               from '../components/Footer'
import Advert               from '../components/Advert'
import ArticleLinks         from '../components/ArticleLinks'

import './ArticlesScreen.css'


const ArticlesScreen = () => {

    const imageDir = `/img`


    return (

    <>
        <Helmet>
            <title>Articles</title>
            <meta name="description" content="Explore our comprehensive collection of articles. Dive into detailed guides, insights, and updates related to VAT calculations, regulations, and best practices." />
        </Helmet>

        <Header />

        <main className='py-3'>

        <Container id="ArticlesScreen" className="copy">

        <Row>
            <Col>
                <Advert />
            </Col>

            <Col xs={8}>

                <section id="heading">
                    <h1 className="display-4">Value-Added Tax Articles</h1>

                    <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                        <img src={`${imageDir}/sitemap.jpg`} alt="Sitemap for CalcVAT.co.uk" />
                    </p>
                </section>

                <section id="articles">
                    <ArticleLinks />
                </section>
            </Col>

            <Col>
                <Advert />
            </Col>
        </Row>

        </Container>
        </main>

        <Footer />
    </>
    )
}

export default ArticlesScreen
