import React                    from 'react'
import { Container, Row, Col }  from 'react-bootstrap'
import { Helmet }               from 'react-helmet'
import { HashLink }             from 'react-router-hash-link'

import Header               from '../components/Header'
import Footer               from '../components/Footer'
import Advert               from '../components/Advert'

import './ReclaimVatScreen.css'


const ReclaimVATScreen = () => {

    const imageDir = `/img`


    return (
    <>
        <Helmet>
            <title>How to Reclaim VAT on Business Expenses</title>
            <meta name="description" content="Unlock the nuances of reclaiming VAT on business expenses. Dive into detailed steps, eligibility, and tips to maximize your VAT returns and ensure compliance." />
        </Helmet>

        <Header />

        <main className='py-3'>

        <Container id="ReclaimVatScreen" className="copy">

        <Row>
            <Col>
                <Advert />
            </Col>

            <Col xs={8}>
                <section id="heading">
                    <h1 className="display-4 fw-normal">How to Reclaim VAT on Business Expenses</h1>

                    <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                        <img src={`${imageDir}/vat-refund.jpg`} alt="how to reclaim VAT" />
                    </p>

                    <p>
                    If you’re new to the world of VAT, it’s understandable that you might see it as just one more thing
                    on a never ending todo list.  Indeed, VAT recording can be demanding and at times a little confusing.
                    Thankfully though, it does come with its perks!
                    </p>

                    <section id="snippet">
                        <p>
                        <strong>
                        In fact, being registered for VAT means that that you can claim back VAT on all kinds of purchases
                        made for your business.  From goods and services to fuel and utility bills; provided it is strictly
                        for your business, you can fully or partially reclaim the tax.  That’s why it’s important to us that
                        you know how!
                        </strong>
                        </p>
                    </section>

                    <h2>In this Article</h2>
                    <ul>
                        <li><HashLink smooth to='#who-is-eligible-to-reclaim-vat'>Who is Eligible to Reclaim VAT</HashLink></li>
                        <li><HashLink smooth to='#what-you-can-reclaim-on-vat'>What you Can and Cannot Reclaim on VAT</HashLink></li>
                        <li><HashLink smooth to='#keeping-accurate-vat-records'>Keeping Accurate VAT Records</HashLink></li>
                        <li><HashLink smooth to='#submitting-vat-returns'>Submitting VAT Returns</HashLink></li>
                        <li><HashLink smooth to='#common-mistakes-to-avoid'>Common Mistakes to Avoid</HashLink></li>
                    </ul>
                </section>

                <section id="who-is-eligible-to-reclaim-vat">

                    <h2>Who is Eligible to Reclaim VAT?</h2>
                    <p>
                    If you have gotten this far as a business, you will probably know that you can only reclaim VAT for purchases made
                    by your business if your business is VAT registered - even if you are voluntarily registered under the £85,000 threshold.
                    Registering for VAT isn't difficult, so if you&apos;re thinking that it may be the right move for your business to become registered,
                    take a look at our guide on <HashLink smooth to='/register-for-vat-uk'>becoming VAT registered</HashLink>.

                    Those eligible to reclaim VAT on business expenses includes:
                    </p>

                    <ul>
                        <li>Sole Traders</li>
                        <li>Limited Companies</li>
                        <li>Self-Employed</li>
                    </ul>
                </section>

                <section id="what-you-can-reclaim-on-vat">
                    <h2>What you Can and Cannot Reclaim on VAT</h2>
                    <p>
                    Firstly, you should know that you can reclaim VAT for on goods or services bought prior to registering your business for VAT.
                    So long as you have sufficient evidence that you bought said items for your business, and have the VAT invoices to prove it,
                    you are eligible to reclaim. This includes any goods bought up to 4 years before registering and any services 6 months before
                    registering.
                    </p>

                    <p>
                    Of course, once you are registered you may reclaim VAT on all non-exempt goods and services. However, claims may only be for
                    items bought specifically for your business and not for personal use. If you spend money on items that are both for personal
                    and business use, then you may be able to reclaim partial tax.
                    </p>

                    <p>
                    For example, your mobile phone may be used for personal reasons but also for business. Therefore, you may claim 50% of the
                    VAT on the purchase of the phone as well as the service. Similarly, if you use a car which is strictly for your business,
                    you may reclaim 100% of the VAT. If the car is for both personal and business purposes, you may reclaim 50% of the VAT.
                    </p>

                    <p>
                    Whatever it is you are reclaiming on, you must be able to provide evidence for its usage. HMRC will request VAT invoices
                    and records to support your claim and how you calculated the business proportion of your purchase.
                    </p>

                    <p>
                    Common items businesses reclaim on include:
                    </p>
                    <ul>
                        <li>Stationery              – printing paper, postage stamps, pens, etc</li>
                        <li>IT equipment            – laptops, software, and maintenance services etc.</li>
                        <li>Premises                – rent, insurance, security, and utility bills etc.</li>
                        <li>Stock                   – items bought for resale; raw materials bought to produce items</li>
                        <li>Professional services   – legal, finance and other services for running your business</li>
                        <li>Travel expenses         – food, accommodation, transportation</li>
                    </ul>
                </section>

                <section id="keeping-accurate-vat-records">
                    <h2>Keeping Accurate VAT Records</h2>

                    <p>
                    To reclaim VAT, it is crucial to keep accurate records of all purchases that you make, including the VAT paid.
                    This should come in the form of invoices, receipts, and other relevant documents. You should also make sure
                    that your invoices contain all the required information, such as:
                    </p>

                    <ul>
                        <li>VAT number</li>
                        <li>The supplier&apos;s VAT number</li>
                        <li>A description of the goods or services purchased</li>
                    </ul>

                    <p>
                    If you are reclaiming VAT on something such as fuel, you should keep a mileage log to demonstrate the business
                    use of the vehicle.
                    </p>
                </section>

                <section id="submitting-vat-returns">
                    <h2>Submitting VAT Returns</h2>
                    <p>
                    If you keep your records organised and accurate, then submitting your VAT return should be a breeze. To reclaim VAT, you
                    must include proof of VAT invoices and retailer receipts along with your VAT return form via the new “Making Tax Digital
                    (MTD) - compatible accounting software.
                    </p>

                    <p>
                    Most businesses submit a VAT return every three months, although some may do so on an annual basis. For instance, if you
                    join a VAT scheme. HMRC will then process all VAT paid and received by your business and send any VAT owed directly into
                    your designated bank account. This usually takes around thirty days (about four and a half weeks).
                    </p>

                    <p>
                    If HMRC needs to verify any information or documents, it may take longer so it is worth getting it right the first time to
                    save yourself unnecessary hassle. Similarly, if HMRC denies your claim you will be notified, and you may appeal the decision.
                    However, you should seek professional advice before doing so.
                    </p>

                    <h3>TOP TIP</h3>
                    <p>
                    If the tax-inclusive value of a purchase is below £250 then less detailed tax invoices are allowed. These include
                    receipts from places like petrol stations and high street shops. In this instance, just a transaction on your bank statement
                    should suffice. Moreover, you may reclaim VAT on purchases up to £25 without a receipt so long as it is from a VAT registered
                    business.
                    </p>
                </section>

                <section id="common-mistakes-to-avoid">
                    <h2>Common Mistakes to Avoid</h2>
                    <p>
                    There are several common mistakes that businesses make when reclaiming VAT. These include:
                    </p>

                    <ul>
                        <li>Not keeping accurate records of purchases and VAT paid</li>
                        <li>Not including all the required information on invoices</li>
                        <li>Reclaiming VAT on purchases that are not eligible, such as purchases for personal use or non-business purposes</li>
                        <li>Failing to submit VAT returns on time</li>
                        <li>Failing to pay any VAT due on time</li>
                        <li>Failing to include all the VAT paid on purchases in the VAT return</li>
                    </ul>

                    <p>
                    To avoid these mistakes, it is important to ensure that your company personnel has a clear understanding of the VAT rules
                    and regulations and to keep accurate records of all your purchases and VAT paid.
                    </p>
                </section>
            </Col>

            <Col>
                <Advert />
            </Col>
        </Row>

        </Container>

        </main>

        <Footer />
    </>
    )
}

export default ReclaimVATScreen
