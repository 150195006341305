import React                    from 'react'
import { Container, Row, Col }  from 'react-bootstrap'
import { Helmet }               from 'react-helmet'

import Header                   from '../components/Header'
import LandingFooter                   from '../components/LandingFooter'
import Calculator               from '../components/Calculator'
import TransactionHistory       from '../components/TransactionHistory'
import ArticlesCarousel         from '../components/ArticlesCarousel'
import Hero                     from '../components/Hero'
import GettingStarted           from '../components/GettingStarted'
import CookieBanner             from '../components/CookieBanner'
import Advert                   from '../components/Advert'

import useStore                 from "../state/GlobalState"



const LandingScreen = () => {

    const siteTitle = useStore((state) => state.siteTitle);

    return (

    <>
        <Helmet>
            <title>{siteTitle}</title>
            <meta name="description" content="CalcVAT.co.uk has the UK's favourite VAT Calculator for flat rate VAT calculation. Explore our VAT related information resources and guides to demystify UK VAT." />
        </Helmet>

        <CookieBanner />

        <Header />

        <Hero />

        <Container id="LandingScreen" className="main copy">

            <Row><Col style={{height: '1em'}}></Col></Row>

            <Row>
                <Col>
                    <Advert />
                </Col>
            </Row>

            <Row><Col style={{height: '1em'}}></Col></Row>

            <section id="calcvat-vat-calculator">

                <h2>CalcVAT VAT Calculator</h2>
                <Row>
                    <Col xs={2} md={2}>
                        <Advert />
                    </Col>

                    <Col>
                        <Calculator />
                    </Col>

                    <Col>
                        <TransactionHistory />
                    </Col>

                    <Col xs={2} md={2}>
                        <Advert />
                    </Col>
                </Row>

            </section>

            <Row><Col style={{height: '4em'}}></Col></Row>

            <GettingStarted />

            <Row><Col style={{height: '4em'}}></Col></Row>

            <Row>
                <Col>
                    <Advert />
                </Col>
            </Row>

            <Row><Col style={{height: '2em'}}></Col></Row>

            <section id="articles" className="squareMargins">
            <Row>
                <Col>
                    <h2>Value-Added Tax Articles</h2>
                    <ArticlesCarousel />
                </Col>
            </Row>
            </section>
            
            <Row><Col style={{height: '2em'}}></Col></Row>

            <Row>
                <Col>
                    <Advert />
                </Col>
            </Row>

            <Row><Col style={{height: '2em'}}></Col></Row>
        </Container>

        <LandingFooter />
    </>
    )
}

export default LandingScreen;
