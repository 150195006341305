import React from 'react'
import ReactDOM from 'react-dom/client'
import { Helmet } from 'react-helmet'

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";


import LandingScreen              from './screens/LandingScreen'
import CalculatorScreen           from './screens/CalculatorScreen'
import CurrentVATRateScreen       from './screens/CurrentVATRateScreen'
import WhatIsVATScreen            from './screens/WhatIsVATScreen'
import FlatRateVATScreen          from './screens/FlatRateVATScreen'
import CalcVATFormulaScreen       from './screens/CalcVATFormulaScreen'
import RegisterForVatScreen       from './screens/RegisterForVatScreen'
import SubmittingVatReturn        from './screens/SubmittingVatReturn'
import ReclaimVatScreen           from './screens/ReclaimVatScreen'
import AnnualAccountingVATScreen  from './screens/AnnualAccountingVATScreen'
import IssueVatInvoiceScreen      from './screens/IssueVatInvoiceScreen'
import RetailVatSchemesScreen     from './screens/RetailVatSchemesScreen'

import ArticlesScreen             from './screens/ArticlesScreen'
import SiteIndexScreen            from './screens/SiteIndexScreen'
import AboutUsScreen              from './screens/AboutUsScreen'
import ContactUsScreen            from './screens/ContactUsScreen'
import PartnerIndexScreen         from './screens/PartnerIndexScreen'
import PartnersByCountyScreen     from './screens/PartnersByCountyScreen'

import TosScreen                  from './screens/TosScreen'
import PrivacyPolicyScreen        from './screens/PrivacyPolicyScreen'
import Basic404Screen             from './screens/Basic404Screen'

import './themes/Litera/bootstrap.min.css'
import './App.css'


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/'                               element={ <LandingScreen /> }         exact />
      <Route path='/vat-calculator'                 element={ <CalculatorScreen /> }      exact />
      <Route path='/articles'                       element={ <ArticlesScreen /> }        exact />
      <Route path='/site-index'                     element={ <SiteIndexScreen /> }       exact />
      <Route path='/about-us'                       element={ <AboutUsScreen /> }         exact />
      <Route path='/contact-us'                     element={ <ContactUsScreen /> }       exact />
      <Route path='/partner-index'                  element={ <PartnerIndexScreen /> }    exact />

      <Route path='/what-is-vat'                    element={ <WhatIsVATScreen />}        exact />
      <Route path='/current-vat-rate'               element={ <CurrentVATRateScreen /> }  exact />
      <Route path='/flat-rate-vat'                  element={ <FlatRateVATScreen /> }     exact />
      <Route path='/uk-vat-formula'                 element={ <CalcVATFormulaScreen /> }  exact />
      <Route path='/register-for-vat-uk'            element={ <RegisterForVatScreen /> }  exact />
      <Route path='/submit-your-vat-return'         element={ <SubmittingVatReturn /> }   exact />
      <Route path='/reclaim-vat'                    element={ <ReclaimVatScreen /> }      exact />
      <Route path='/annual-accounting-vat-scheme'   element={ <AnnualAccountingVATScreen /> }  exact />
      <Route path='/issuing-vat-invoices'           element={ <IssueVatInvoiceScreen /> } exact />
      <Route path='/retail-vat-schemes'             element={ <RetailVatSchemesScreen /> } exact />

      <Route path='/partners/:country/:county'      element={ <PartnersByCountyScreen /> }      exact />

      <Route path='/privacy'                        element={ <PrivacyPolicyScreen /> }   exact />
      <Route path='/tos'                            element={ <TosScreen /> }             exact />
      <Route path='/404'                            element={ <Basic404Screen /> }        exact />

      <Route
        path    ='*'
        element = { <Basic404Screen /> }
        status  = { 404 }
      />
    </>
  )
);


const canonicalHRef = "https://www.calcvat.co.uk" + window.location.pathname;

ReactDOM.createRoot(document.getElementById('root')).render(
    <>
      <div>
        <Helmet>
          <link rel="canonical" href={canonicalHRef} />
        </Helmet>
      </div>

      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </>
);
