import React        from 'react'
import YoutubeVideo from './YoutubeVideo'
import useStore     from '../state/GlobalState'

import './Hero.css'


const Hero = () => {

    const siteTitle = useStore((state) => state.siteTitle);
    const embedCode  = '9kxTjEo04yo';

    return (
    <>
    <section id="hero" className="cointainer-fluid hero-header text-light bg-dark py-5 mb-5">

        <div className="row g-5 align-item-center">

            <div id="titleText" className="col-lg-4">
                <h1 className="animate__animated animate__slideInRight">{siteTitle}</h1>
                <ul className="animate__animated animate__slideInDown">
                    <li><h2>Easily apply and Remove Value-Added Tax</h2></li>
                    <li><h2>Download Transaction History in CSV Format</h2></li>
                </ul>
            </div>

            <div className="col-lg-6">
                <div id="promo-video">
                    <YoutubeVideo videoEmbedCode={embedCode} videoTitle={siteTitle} />
                </div>
            </div>

        </div>

    </section>
    </>
    )
}

export default Hero
