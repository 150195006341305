import React from 'react'
import './SocialMediaLinks.css'


const SocialMediaLinks = () => {

    const facebookLink  = process.env.REACT_APP_FB_LINK;
    const twitterLink   = process.env.REACT_APP_TWITTER_LINK;
    const youtubeLink   = process.env.REACT_APP_YOUTUBE_LINK;
    const instagramLink = process.env.REACT_APP_INSTAGRAM_LINK;
    //const pinterestLink = process.env.REACT_APP_PINTEREST_LINK;

    return (
    <>
        <section id="SocialMediaLinks">

            <h5 className="mb-4">Shout Out</h5>
            <p>Please Give us a shout out on social media!</p>

            <div className="d-flex">
                <a className="btn rounded-circle me-4" href={twitterLink}>
                  <i className="bi bi-twitter"></i>
                </a>
                <a className="btn rounded-circle me-4" href={facebookLink}>
                  <i className="bi bi-facebook"></i>
                </a>
                <a className="btn rounded-circle me-4" href={youtubeLink}>
                  <i className="bi bi-youtube"></i>
                </a>
                <a className="btn rounded-circle me-4" href={instagramLink}>
                  <i className="bi bi-instagram"></i>
                </a>
            </div>
       </section>
    </>
    )
}

export default SocialMediaLinks
