import React from 'react';

import SiteLogo          from './SiteLogo'
import CompanyAddress    from './CompanyAddress'
import SocialMediaLinks  from './SocialMediaLinks'
import QuickLinksMenu    from './QuickLinksMenu'
//import NewsletterForm    from './NewsletterForm'

import './LandingFooter.css'


const LandingFooter = () => {
  return (
    <>
    <footer>
      <div className="container-fluid bg-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">

        <div className="container py-5">
            <div className="row g-5">
                <div className="col-md-6">
                    <h2 className="text-primary mb-4">
                      <i className="bi bi-calculator"></i>
                      CalcVAT.co.uk
                    </h2>
                    <span>
                      We&apos;d love to hear your feedback, please get in touch.
                    </span>
                </div>

                <div className="col-md-6">
                </div>

                <div id="logoContainer" className="col-lg-3 col-md-6">
                  <SiteLogo />
                </div>

                <div id="get-in-touch" className="col-lg-3 col-md-6">
                    <CompanyAddress />
                </div>

                <div className="col-lg-3 col-md-6">
                    <QuickLinksMenu />
                </div>

                <div className="col-lg-3 col-md-6">
                    <SocialMediaLinks />
                </div>

            </div>
        </div>
        <div className="container-fluid copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        &copy; 2023 <a href="https://dramallama.ltd">dramallama.limited</a>, all rights reserved.
                    </div>
                </div>
            </div>
        </div>
    </div>
    </footer>
    </>
  );
};

export default LandingFooter
