import React                            from 'react'
import { Container, Row, Col, Table }   from 'react-bootstrap'
import { Helmet }                       from 'react-helmet'
import { Link }                         from 'react-router-dom'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Advert from '../components/Advert'

import './WhatIsVATScreen.css'


const WhatIsVATScreen = () => {

    const imageDir      = `/img`
    const hmrcLink      = 'https://www.gov.uk/government/organisations/hm-revenue-customs';
    const hmrcInstagram = 'https://www.instagram.com/hmrcgovuk/';


    return (
        <>
        <Helmet>
            <title>Understanding UK VAT: An Introduction</title>
            <meta name="description" content="Dive into UK VAT basics with calcvat.co.uk. Our introductory guide demystifies concepts, ensuring a solid foundation in VAT understanding." />
        </Helmet>

        <Header />
        <main className='py-3'>

        <Container id="WhatIsVAT">
        <Row>
            <Col>
                <Advert />
            </Col>

            <Col xs={8}>
                <h1 className="display-4">Understanding UK VAT: An Introduction</h1>

                <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                    <img src={`${imageDir}/what-is-vat.jpg`} alt="What is VAT?" />
                </p>

                <br />

                <h3>A Definition for Value Added Tax (VAT)</h3>
                <section id="snippet" description="">
                    <p>
                    <strong>VAT is a sales tax levied on goods and services sold in the UK, with different rates applied to the salable goods or services depending on rules laid out by HMRC.
                    <Link to="/current-vat-rate">The standard rate of VAT in the UK is currently 20%</Link>, although some goods
                    and services are taxed at the reduced rate of 5% or are completely exempt.
                    </strong>
                    </p>
                </section>

                <h3>VAT Input Tax and VAT Output Tax </h3>
                <p>
                VAT registered UK businesses are required to charge their customers VAT on the goods and services they sell.  They must
                also pay VAT on the goods and services they buy, but they may can reclaim the VAT they pay on their purchases through their
                VAT returns.

                The VAT collected on sales is known as VAT output tax, while VAT paid on purchases is called the VAT input tax.
                </p>

                <h3>VAT is an Indirect Tax</h3>
                <p>
                VAT is passed on to the end consumer through the price of the goods or services, so is known as an indirect tax.  However,
                VAT registered businesses may claim back the VAT they have paid on their purchases, helping reduce the total cost
                of doing business.
                </p>

                <br />

                <h3>Calculating VAT</h3>
                <p>
                This is the process of determining the amount of VAT that a business must collect on its sales and pay on its purchases.
                </p>

                <h4>VAT on Purchases</h4>
                <p>
                To calculate the VAT a business must pay on its purchases, it must first determine the total value of its taxable purchases.
                This includes any goods or services that are subject to VAT at the applicable rates - standard rate (20%), reduced rate (5%),
                or zero rate.  Next, the business should calculate VAT on these purchases by multiplying the total value of the purchases
                by the applicable VAT rate.
                </p>

                <h5>A Quick Example for VAT on Purchases</h5>
                <p>
                For taxable purchases of £100,000 at standard rate VAT, the VAT liability on these purchases
                would be £20,000:
                <br/>
                <b>100,000 x 0.2 = 20,000</b>
                <br/>
                <i><small>calculation of 20% of 100,000</small></i>
                </p>

                <h4>VAT on Sales</h4>
                <p>
                The process is similar for calculation of VAT that a business should collect on its sales.
                The business must first determine the total value of taxable sales and multiply it by the applicable VAT rate.
                </p>

                <h5>A Quick Example for VAT on Sales</h5>
                <p>
                For taxable sales of £120,000 at the standard VAT rate of 20%, the VAT liability on these sales would be £24,000
                <br/>
                <b>120,000 x 0.2 = 24,000</b>
                <br/>
                <i><small>calculation of 20% of 120,000</small></i>
                </p>


                <h4>Net Liability</h4>
                <p>
                Once the VAT liability on both purchases and sales has been calculated, the business can then determine its net VAT liability by
                deducting the VAT paid on purchases (VAT input tax) from the VAT collected on sales (VAT output tax).  If the VAT output tax is
                greater than the VAT input tax, the business will owe the difference to HMRC.  If the VAT input tax is greater than the VAT output
                tax, the business will be entitled to a VAT refund from HMRC.
                </p>

                <p>
                It is important for businesses to keep accurate records of their taxable sales and purchases in order to accurately calculate their
                VAT liability. HMRC may audit a business&apos;s VAT records to ensure that the correct amount of VAT has been paid or collected.
                </p>

                <br />

                <h3>The UK's Annual Gross VAT Revenue</h3>
                
                <p>
                The United Kingdom government relies heavily on value-added tax (VAT) as a source of revenue; as an income stream, it's second only 
                to income tax for the UK government.  
                </p>
                
                <p>Between the period 2010-2020, the UK VAT revenue has been worth more than a cool Trillion pounds, 
                thats more than 100 billion GBP per year.
                </p>

                <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                    <img src={`${imageDir}/gross_uk_vat_revenue.JPG`} alt="UK Gross Annual VAT Revenue for the period 2010 - 2020" />
                </p>

                <Table striped bordered hover className="table table-sm">
                <thead>
                <tr className="table-primary">
                    <th>Year</th>
                    <th>Gross VAT Revenue (Billions)</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>2010</td>
                        <td>101.286</td>
                    </tr>
                    <tr>
                        <td>2011</td>
                        <td>114.204</td>
                    </tr>
                    <tr>
                        <td>2012</td>
                        <td>117.312</td>
                    </tr>
                    <tr>
                        <td>2013</td>
                        <td>123.592</td>
                    </tr>
                    <tr>
                        <td>2014</td>
                        <td>128.316</td>
                    </tr>
                    <tr>
                        <td>2015</td>
                        <td>133.953</td>
                    </tr>
                    <tr>
                        <td>2016</td>
                        <td>139.483</td>
                    </tr>
                    <tr>
                        <td>2017</td>
                        <td>143.646</td>
                    </tr>
                    <tr>
                        <td>2018</td>
                        <td>151.803</td>
                    </tr>
                    <tr>
                        <td>2019</td>
                        <td>153.723</td>
                    </tr>
                    <tr>
                        <td>2020</td>
                        <td>137.657</td>
                    </tr>
                </tbody>
                </Table>

                <p>
                Source: <a href="https://www.ons.gov.uk/economy/governmentpublicsectorandtaxes/publicsectorfinance/datasets/countryandregionalpublicsectorfinancesrevenuetables">Office of National Statistics, Revenue Tables</a>
                </p>

                <p>
                VAT revenue does tend to fluctuate depending on economic conditions.  Data from the UK Office for National Statistics shows that the total 
                VAT revenue for the UK for the 2020-2021 fiscal year was £137 billion.
                </p>

                <p>
                The decrease in 2020 VAT revenue from previous annual VAT revenue can be attributed to the economic impact of the COVID-19 pandemic, as the 
                government perhaps wrongly forced many businesses to close or operate at reduced capacity.  This inevitably lead to a decrease in consumer 
                spending and a corresponding decrease in VAT revenue.
                </p>

                <p>
                Despite this, the UK government has in recent times failed to collect more than £40 billion in tax revenue, and have 
                been <a href="https://uk.news.yahoo.com/42-billion-unpaid-taxes-owed-000100564.html">roasted in both the house of commons and in the press</a>
                &nbsp; for it.
                </p>

                <br />
                <h3>The VAT Origin Story</h3>
                <p>
                    <b>Value-Added Tax (VAT) is a type of consumption tax which is levied on goods and services.</b>&nbsp;&nbsp;
                </p>
                <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                    <img src={`${imageDir}/tax_squeeze_wallet.jpg`} alt="Value-Added Tax (VAT)" />
                </p>

                <p style={{ marginTop: '0.5em'}}>
                    <i>VAT was first introduced in France in 1954 as a means of financing the country's post-World War II reconstruction efforts.</i>
                </p>
                <p>
                    Governments around the world took notice and VAT quickly recognition and adoption by a number of other countries.  Almost seventy years later,
                    VAT remains a favoured means of harvesting revenue from citizens in more than one hundred and sixty countries.
                </p>
                <p>
                    VAT is levied at each stage of the production and distribution process and is ultimately passed on to the end consumer through
                    the price of the goods and services.
                </p>
                <p>
                    VAT was introduced to the UK in 1973 as a replacement for the Purchase Tax, which was a condition of the UK joining what was then called
                    the European Economic Community.  The UK's VAT system is enforced by His Majestys Revenue and Customs (HMRC).
                </p>
                <p>
                    VAT rates vary from country to country and may change over time.  At the moment, the UK VAT rate on most goods and services is 20%, although
                    some goods and services may be taxed at different rates or may be exempt from VAT.
                </p>
                <p>
                    Businesses registered for VAT are required to charge VAT on the goods and services they sell and to pay VAT on the goods and
                    services they purchase, although they may be able to recover the VAT paid on their purchases through their VAT returns.
                </p>

                <br />

                <h3>HMRC&apos;s Promise</h3>
                <p>
                His Majesty's Revenue and Customs (HMRC) is the UK government agency responsible for
                administering and enforcing VAT.  Failure of a business to correctly calculate and report their VAT liability can result in significant
                fines and penalties.
                </p>

                <br />

                <h3>The HMRC Website</h3>
                <p>
                You can find out more about everyone&apos;s favourite government agency at <a href={hmrcLink}>the HMRC website</a>.
                HMRC have fully embraced social media and have their own presence on all the major platforms.  So if you&apos;re
                feeling especially adventurous, you could check out <a href={hmrcInstagram}>the offical HMRC instagram account!</a>
                </p>
            </Col>

            <Col>
                <Advert />
            </Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        <Row>
            <Col>
                <Advert />
            </Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        </Container>

        </main>
        <Footer />
        </>
    )
}

export default WhatIsVATScreen
