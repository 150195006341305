import React                    from 'react'
import { Container, Row, Col }  from 'react-bootstrap'
import { Helmet }               from 'react-helmet'

import { HashLink }             from 'react-router-hash-link'

import Header                   from '../components/Header'
import Footer                   from '../components/Footer'
import Advert                   from '../components/Advert'

import './SubmittingVatReturn.css'


const SubmittingVatReturn = () => {

    const imageDir = `/img`


    return (
    <>
        <Helmet>
            <title>How to Submit your VAT Return</title>
            <meta name="description" content="Master VAT return submissions with calcvat.co.uk. Follow our guide to ensure timely, accurate, and compliant submissions" />
        </Helmet>

        <Header />

        <main className='py-3'>

        <Container id="SubmittingVatReturn" className="copy">

        <Row>
            <Col>
                <Advert />
            </Col>

            <Col xs={8}>

                <section id="heading">
                    <h1 className="display-4 fw-normal">How to Submit Your VAT Return</h1>

                    <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                        <img src={`${imageDir}/vat-return.jpg`} alt="How to Submit Your VAT Return" />
                    </p>

                    <p>
                    So, you&apos;ve been charging your customers VAT on sales, you&apos;ve kept a record of all the transactions, now its time to get
                    them all sent off to HMRC.  However, if you&apos;re feeling uncertain about how to do your VAT return correctly, well fear not!
                    This article covers everything you need to know about submitting your VAT return, leaving you with much-deserved peace of mind.
                    </p>
                </section>

                <section id="snippet">
                    <h2>Key Points On Submitting your VAT Return</h2>
                    <ul>
                    <li>Submission of your VAT return to HMRC is normally on a quarterly basis.</li>
                    <li>There are tax schemes available that simplify the process and allow for an annual submission.</li>
                    <li>VAT returns are made through "Making Tax Digital" compatible software</li>
                    </ul>
                </section>

                <section id="in-this-article">
                    <h2>In this Article</h2>
                    <ul>
                        <li><HashLink smooth to='#what-is-a-vat-tax-return'>What is a VAT Tax Return?</HashLink></li>
                        <li><HashLink smooth to='#who-needs-to-do-vat-returns'>Who needs to Submit VAT Returns?</HashLink></li>
                        <li><HashLink smooth to='#what-to-include-on-a-vat-return'>What to Include on a VAT Return</HashLink></li>
                        <li><HashLink smooth to='#when-to-send-your-vat-return'>When you should Submit Your VAT Return</HashLink></li>
                        <li><HashLink smooth to='#how-to-submit-your-vat-return'>How to Submit Your VAT Return</HashLink></li>
                        <li><HashLink smooth to='#how-to-pay-vat-returns'>Paying VAT Returns</HashLink></li>
                        <li><HashLink smooth to='#key-takeaways'>Key Takeaways</HashLink></li>
                    </ul>
                </section>

                <section id="what-is-a-vat-tax-return">
                    <h2>What is a VAT Tax Return?</h2>
                    <p>
                    A VAT tax return is a statement which businesses submit throughout the year to declare all VAT received on goods
                    and services sold.  It also declares all VAT charged on purchases made by the business.  So, not only does it help
                    you to keep records of all your transactions, but is also useful to calculate your profits, what you can reclaim
                    and what you may have to pay.
                    </p>

                    <p>
                    <strong>
                    You should always keep copies of your VAT returns as they contain important financial information which could be
                    useful for either analysing business performance data or if an investigation is opened by HMRC regarding tax.
                    </strong>
                    </p>
                </section>

                <section id="who-needs-to-do-vat-returns">
                    <h2>Who needs to Submit VAT Returns?</h2>
                    <p>
                    Any business that has an annual taxable profit of over £85,000 must be registered for VAT and submit VAT returns.
                    This includes all companies who sell goods and services but also sole traders, limited company owners and those who
                    are self-employed.
                    </p>

                    <p>
                    If you reside outside of the UK or have a business which operates outside of the UK but sells goods and/or services
                    in the UK which earn over the annual profit threshold of £85,000, then you must also be VAT registered and send VAT returns.
                    </p>
                </section>

                <section id="what-to-include-on-a-vat-return">
                    <h2>What you Must Include on Your VAT Return</h2>
                    <p>
                    Your VAT return should be an exhaustive list of every single transaction made and received by your business. It
                    should also include your calculated totals for VAT you owe, VAT you would like to reclaim and VAT which you are
                    owed from HMRC.  For example, Business expenses.
                    </p>

                    <p>
                    Even if you received goods or services in-lieu of money, such as a part-exchange, you must still charge VAT on the
                    total monetary value of what you receive.  Similarly, if you have not charged VAT to the customer, you are still
                    liable to pay the VAT on the transaction.
                    </p>
                </section>

                <section id="when-to-send-your-vat-return">
                    <h2>When you should Submit Your VAT Return</h2>
                    <p>
                    Most businesses chose to send their VAT returns quarterly throughout the year. This is considered a reasonable
                    amount of time which breaks up annual record keeping and the sending/receiving of VAT payments in line with
                    profits. This time is known as the "accounting period".
                    </p>

                    <p>
                    However, if you are part of a scheme, such as the Flat-Rate scheme or Annual Accounting Scheme, then you only
                    submit one VAT return per year. This is due to a more simplified method of accounting.
                    </p>

                    <p>
                    It is important to note that if you are a business which is registered for VAT you must always submit your VAT
                    returns at the appropriate time, regardless of whether you have any VAT to pay or reclaim.
                    </p>

                    <p>
                    If you miss a VAT return and/or payment, then you will be liable to pay a fee. If you have an emergency issue,
                    then it is advisable you contact HMRC at the earliest opportunity. If you are unavailable at the time your VAT
                    tax return is due, then you must make arrangements to ensure they&apos;re sent on time.
                    </p>
                </section>

                <section id="how-to-submit-your-vat-return">
                    <h2>How to Submit Your VAT Return</h2>
                    <p>
                    The new way of submitting VAT returns is via the new government strategy "Making Tax Digital" (MTD).  To do this you
                    must have MTD-compatible accounting software.  Using this method not only simplifies the input of numerical
                    data but it also efficiently calculates the VAT for you.
                    </p>

                    <p>
                    Alternatively, you could also appoint an accountant to assist you.  An accountant may go so far as to handle all&nbsp;
                    financial record keeping, VAT returns and payments or we can just crunch the numbers when it comes to sending the return&nbsp;
                    – giving you as much or as little control over the process as you would like.
                    </p>

                    <p>
                    Those businesses who are part of a government scheme should note that this could affect how you send your VAT.
                    The Annual Accounting scheme, for example, uses a VAT online account for payments.
                    </p>

                    <p>
                    In this digital era, no businesses submit paper VAT returns.  In fact, all VAT registered businesses must use Making Tax Digital
                    compatible software to submit their VAT returns, unless they are VAT exempt.  Eligibility for exemption includes religious beliefs,
                    an inability to use computers due to a disability or you do not have internet access where you are located.
                    </p>

                    <p>
                    <strong>
                    Be sure to double-check all your numbers are correct and everything has been correctly calculated. Any tax presumed to be under-stated
                    or over-claimed with incur a penalty of up to 100%.
                    </strong>
                    </p>
                </section>

                <section id="how-to-pay-vat-returns">
                    <h2>Paying VAT Returns</h2>
                    <p>The deadline for paying VAT is one calendar month and seven days after the end of the accounting period. For example, for the quarter
                    ending 1st August 2023, your return must be submitted, and payment cleared in HMRC&apos;s account by 7th September 2023.
                    </p>

                    <p>
                    You must consider the time payments take to clear as this will be different depending on how you chose to pay. For instance,
                    same or next day payments can be made online or via telephone, which is advised. Alternatively, you can pay via direct debit,
                    internet banking or BACS. However, these typically take around 3 days to clear.
                    </p>

                    <p>
                    Importantly, you must also consider that payments will not be processed on weekends or bank holidays.
                    </p>
                </section>

                <section id="key-takeaways">
                    <h2>Key Takeaways</h2>
                    <ul>
                        <li>All businesses with an annual profit over £85,000 must submit tax returns</li>
                        <li>Tax returns must include every transaction made and received by your business</li>
                        <li>Tax returns must include the totals of all VAT paid, VAT owed and VAT you would like to reclaim</li>
                        <li>Most VAT returns are submitted quarterly</li>
                        <li>There are schemes available which allow you to submit one annual VAT return</li>
                        <li>VAT returns must be submitted regardless of whether you have not charged or paid any VAT in the accounting period</li>
                        <li>Missed VAT returns and payments incur financial penalties</li>
                        <li>You can send VAT returns online using MTD-compatible software or via an accountant</li>
                        <li>VAT payment must be made one calendar month and 7 days after the accounting period</li>
                    </ul>
                </section>

            </Col>

            <Col>
                <Advert />
            </Col>
        </Row>

        </Container>
        </main>
        <Footer />
    </>
    )
}

export default SubmittingVatReturn
