import React                            from 'react'
import { Container, Row, Col, Table }   from 'react-bootstrap'
import { Helmet }                       from 'react-helmet'

import Header                           from '../components/Header'
import Footer                           from '../components/Footer'
import Advert                           from '../components/Advert'

import './CalcVATFormulaScreen.css'


const CalcVATFormulaScreen = () => {

    const imageDir = `/img`

    return (
    <>
        <Helmet>
            <title>How to Calculate VAT in the UK: A Step-by-Step Guide</title>
            <meta name="description" content="Master UK VAT calculations with our step-by-step guide. Uncover the essentials, tips, and best practices to ensure accurate VAT computations every time." />
        </Helmet>

        <Header />

        <Container id="CalcVATFormulaScreen" className="copy">

        <Row>
            <Col>
                <Advert />
            </Col>


            <Col xs={8}>
                <h1 className="display-4 fw-normal">How to Calculate VAT in the UK: A Step-by-Step Guide</h1>

                <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                    <img src={`${imageDir}/calculator-pen.jpg`} alt="How to Calculate VAT in the UK: A Step-by-Step Guide" />
                </p>

                <p>
                While having a handy dandy online VAT calculator is great, sometimes we need to be able
                to calculate VAT manually.  This might be useful for a Python script or a spreadsheet.
                The formula for doing so is fairly straightforward, but the reverse calculation of VAT formula can be a
                 little bit tricky.
                 </p>

                <section id="snippet" description="UK VAT Calculation: Add VAT and Remove VAT">

                    <p>The formula to calculate UK VAT are:</p>

                    <Table striped hover bordered className="table table-sm">
                        <thead>
                            <tr className="table-primary">
                                <th width="50%">
                                    <h2>Formula to Add VAT</h2>
                                    <strong>1.2 * Value = Vat Inc. Value</strong>
                                </th>

                                <th>
                                    <h2>Formula to Remove VAT</h2>
                                    <strong>VAT Inc. Value / 1.2 = VAT Exc. Value</strong>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>
                                    <br/>
                                    <i>If VAT exc. amount = £500 and VAT rate is 20%, then</i> <br />
                                    1.2 * £500 = £600 <br/><br/>
                                    So, the VAT inclusive amount is £600,<br/>
                                    Meaning a VAT value of £100 <br/><br/>
                                </td>

                                <td>
                                    <br/>
                                    <i>If VAT inc. amount = £500 and VAT rate is 20%, then</i> <br />
                                    500 / 1.2 = £416.67
                                    <br /><br />
                                    So, the VAT exclusive amount is £416.67<br/>
                                    Meaning a VAT value of £83.33<br/>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    <p>That magic 1.2 value, used in both calculations is important.  It comes from the following formula:</p>

                    <p style={{marginLeft: '2em' }}><strong>1 + (Standard VAT Rate / 100)</strong></p>

                </section>

                <p>In today&apos;s article, we explore the formula to add VAT with examples.  We&apos;ll also take a look
                at removing VAT from a VAT inclusive amount, which is a little more tricky.</p>

                <h2>Calulating Standard VAT</h2>
                <p>
                Calculating VAT for an amount is a straightforward calculation that calculates a percentage of the
                amount, which is then added to give a final VAT inclusive amount.  For standard VAT in the UK, the percentage
                applied is 20%.
                </p>

                <h3>An Example for Calculating Standard VAT</h3>
                <p>Lets take a look at an example where we compute the value of VAT to add to the value £250.00.</p>

                <i>
                <div className="example">
                    Add VAT Formula: <br />
                    (1 + (VAT Rate % / 100)) * value <br />
                    Or simply 1.2 * value <br />
                    <br />
                </div>
                </i>

                <p>
                Standard rate VAT @ 20% gives us 1 + (20 / 100) for the first part of the formula, yielding 1.2.  Then we simply
                multiply our value by 1.2 to get the VAT inclusive value.
                </p>

                <p>Lets write this out clearly</p>
                <div className="example">
                    VAT Rate %                      = 20% <br />
                    VAT Exclusive value             = £250.00 <br /><br />

                    <strong>(1 +(VAT Rate % / 100))         = 1 + (20/100) = 1.2</strong><br /><br />

                    1.2 * VAT Exclusive value       = VAT Inclusive value <br />
                    1.2 * 250.00                    = £300.00<br /><br />
                </div>

                <p>In summary, for a VAT exclusive amount of £250.00, at 20% VAT rate, the full VAT inclusive amount is £300.00.</p>

                <h4>A Handy Shortcut to Apply Standard Rate VAT</h4>
                <p>
                The shortcut to calculating the full VAT inclusive amount from the VAT exclusive amount, if you&apos;re
                not interested in knowing the VAT amount is simply to multiply the VAT exclusive value by 1.2.  But remember,
                you'll need to revise this value if the VAT rate changes.
                </p>

                <h2>Reverse Calculation of VAT</h2>
                <p>Removing VAT is often referred to as reverse calculation of VAT.  The formula is a little more tricky to understand, but not much.
                We have to understand that we're dealing with a VAT already applied amount, and so we must calculate how much we need to remove.</p>

                <p>A worked example can always clarify things, so lets look at one now.</p>

                <p>For the sake of brevity, we'll call Vat Rate % VR in the formula below:</p>

                <div className="example">
                    <strong>(VR / (100 + VR)) * amount  = VAT to remove</strong> <br /><br />

                    Vat Rate %                                  = 20% <br />
                    Vat Inclusive value                         = £250.00 <br />
                    Vat Exclusive value                         = ? <br /><br />

                    (VR / (100 + VR)) * Vat Inclusive value     = VAT to remove <br /><br />
                    (20 / (100+20)) * 250.00                    = 0.16667 * 250.00 <br />
                    0.16667 * 250.00                            = 41.67 <br />
                    250.00 - 41.67                              = £208.33 <br /><br />
                </div>

                <p>
                We start with the VAT inclusive amount, a value that is 120% of the VAT exclusive amount.  The tricky part is in understanding that we can't just calculate
                20% of that and subtract, instead we have to understand that if our VAT inc amount is 120%, then we want to find a sixth of the VAT inc amount, then subtract.
                </p>

                <p>
                Why a sixth?  Well, because 20% is 1/6th of 120%.  So in our example, £41.67 is the VAT amount to remove, and so the final VAT exclusive amount is £208.33
                </p>

                <h4>A Handy Shortcut to Remove Standard Rate VAT</h4>
                <p>
                The shortcut to removing vat is also straightforward:
                </p>
                <p><strong>Divide the VAT exclusive value by 1.2</strong></p>

                <div className="example">
                    £250.00 / 1.2  = £208.33 <br /><br />
                </div>


                <h2>Summary</h2>
                <p>
                VAT calculation is an essential skill for anyone involved in business or accounting, so tools like the <a href="/vat-calculator">CalcVAT VAT Calculator</a> and shortcut formulae are welcome relief, but you should
                take time to understand the calculations too.  Remember to keep an eye on any changes to the VAT rate!
                </p>

            </Col>

            <Col>
                <Advert />
            </Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        <Row>
            <Col>
                <Advert />
            </Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        </Container>

        <Footer />
    </>
    )
}

export default CalcVATFormulaScreen
