import React                    from 'react'
import { Container, Row, Col }  from 'react-bootstrap'
import { Helmet }               from 'react-helmet'

import Advert           from '../components/Advert'
import Header           from '../components/Header'
import Footer           from '../components/Footer'
import FlatRateVATTable from '../components/FlatRateVATTable'

import './FlatRateVATScreen.css'

const FlatRateVATScreen = () => {

    const imageDir = `/img`


    return (
    <>
        <Helmet>
            <title>The New Flat Rate VAT Scheme</title>
            <meta name="description" content="Dive into the intricacies of the Flat Rate VAT Scheme. Stay updated with the latest changes, understand its impact, and navigate your VAT calculations confidently." />
        </Helmet>

        <Header />
        <main className='py-3'>

        <Container id="FlatRateVATScreen" className="copy">

        <Row>
            <Col>
                <Advert />
            </Col>

            <Col xs={8}>
                <h1 className="display-4">The New Flat Rate VAT Scheme</h1>
                <br />

                <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                    <img src={`${imageDir}/taxation.jpg`} alt="The Flat Rate VAT Scheme" />
                </p>

                <p>
                Are you a small business with an annual turnover of under £150,000? If so, then HMRC's new VAT Flat
                Rate Scheme might be just the thing for you.
                </p>

                <br />

                <section id="snippet" description="What is the Flat Rate VAT Scheme?">
                    <h2>What is the Flat Rate VAT Scheme?</h2>
                    <p>
                        <strong>The flat rate VAT scheme is a simplified method of calculating and reporting VAT for small businesses in the United Kingdom.
                        The scheme allows small businesses to pay a fixed percentage of their gross sales (excluding VAT) as VAT, rather than calculating
                        and reporting VAT on individual sales and purchases.</strong>
                    </p>
                </section>

                <br />

                <p>
                Keep reading to find out more, and get questions answered such as:
                    <ul>
                        <li>Is your business eligible?</li>
                        <li>What are the pros and cons of the scheme?</li>
                        <li>And how can you apply for the VAT Flat Rate Scheme?</li>
                    </ul>
                </p>
                <br />

                <h2>What Is VAT?</h2>
                <p>
                We've explored the question <a href="/what-is-vat">What is VAT?</a> in a recent article, so this'll just be a
                brief executive summary.
                </p>

                <p>
                VAT (Value-Added Tax) is paid on almost everything we buy.  From laundry detergent to take-away coffee, gym
                membership to legal fees.  They all come with value-added tax, which increases the price of goods and
                services by 20% on a standard rate.  If you have a business that makes an annual profit over £85,000,
                your business is legally required to register with HMRC and charge VAT on everything you sell.
                </p>

                <p>VAT is a consumption tax paid by the customer to the government via your business.  As a VAT registered
                business owner, it is your responsibility to keep track of the all VAT paid by your customers.  VAT that you
                pay purchasing items for your business will then be subtracted from that total - essentially paying the difference.
                </p>

                <p>
                Every 3 to 4 months you are expected to declare all VAT transactions via tax returns sent to HMRC,
                and pay them accordingly.

                If you&apos;ve charged more VAT than you&apos;ve paid then you will be asked to pay the remaining amount.
                Equally, if you’ve paid more VAT than you&apos;ve charged then HMRC will reimburse the difference.
                </p>

                <br />

                <p>
                This can all become as complicated as it sounds. However, to ease this difficulty and to encourage the opening
                of new businesses in the UK, HMRC have introduced a new scheme to make things a little easier.
                </p>
                <br />

                <h2>Introducing the New Flat Rate VAT Scheme</h2>
                <p>
                The new Flat Rate VAT Scheme allows small businesses the option to pay VAT at a fixed rate percentage, rather than
                the standard rate.  How it works is simple.  Each business category has been assigned a specific flat rate.
                The flat rate percentage is multiplied by your annual turnover.  This will determine how much your business will pay in VAT.

                The following table shows the percentage of VAT required per business category.  If you're not sure which business category
                your business fits into, you should discuss this with your accountant.
                </p>

                <FlatRateVATTable />

                <br />

                <h3>A Simple Example</h3>
                <p>
                Under the new flat rate VAT scheme, transport companies for example have a flat rate of 10%. This means that if a transport company
                earned an annual profit of £90,000, the flat rate VAT owed to HMRC would be £9000, (£90,000 x 10% = £9000). As such, the
                lower your annual profit, the lower the amount you will have to pay. Furthermore, HMRC are currently offering a 1% discount
                for newly registered VAT businesses. Therefore, a transport business in its first year would only have to pay 9% flat rate
                VAT, amounting to £8100 - a saving of £900!
                </p>
                <br />

                <p>
                It should be noted that you may only remain on the flat rate until your business exceeds an annual turnover of £230,000, at
                which point you will be switched onto the standard 20% rate.  However, until you reach that amount, the scheme gives you the
                potential to save money, thus retaining the difference between 20% VAT charged to customers and flat rate VAT paid to HMRC.

                It also saves a lot of your valuable time and effort.  Additionally, many new businesses have claimed that it helps to manage
                cash-flow and ease record keeping, allowing them to focus on more important things like building their brand and connecting
                with new customers.
                </p>
                <br />

                <p>
                Of course, this scheme is not a one-size-fits-all.  For instance, if your company spends a small amount on goods (less than
                £1000 a year or more than 2% of revenue) then you are classed as a "limited cost business" and therefore must pay a higher
                rate of 16.5%.  Also under the scheme, VAT cannot be reclaimed on any company purchases unless it is a capital asset
                over £2,000 including VAT.  Businesses that purchase a high volume of goods would therefore not benefit from this scheme.
                </p>
                <br />

                <h2>Is my Business Eligible for the new Flat Rate VAT Schame?</h2>
                <p>
                To be considered eligible for the new flat rate VAT scheme, your business must:
                </p>
                <ul>
                    <li>Be a VAT registered business</li>
                    <li>Have a predicted annual turnover of less than £150,000 (excluding VAT)</li>
                </ul>

                <br />

                <h2>What Criteria might make me Ineligible for the new Flat Rate VAT Scheme?</h2>
                <p>
                Your business will not be eligible to apply for the new flat rate VAT scheme if you have:
                </p>
                <ul>
                    <li>Left the scheme in the last 12 months</li>
                    <li>Committed a criminal offence involving tax in the last 12 months</li>
                    <li>Joined or are eligible to join a VAT group in the last 24 months</li>
                    <li>Registered business division VAT in the last 24 months</li>
                    <li>Are closely linked to another business financially, economically or organisationally</li>
                    <li>Are already part of a margin or capital goods VAT scheme</li>
                </ul>

                <br />

                <h2>What are the Pros & Cons to Joining the new Flat Rate VAT Scheme?</h2>
                <p>
                There are always pros and cons which must be considered for these schemes and will vary from business to business.
                They are as follows:
                </p>

                <h3>Pros</h3>
                <ul>
                    <li>It&apos;s easy to register for the flat rate VAT scheme</li>
                    <li>You may withdraw from the scheme at any time</li>
                    <li>You keep the difference between the 20% VAT charged to customers and your flat rate</li>
                    <li>You&apos;ll save money</li>
                    <li>Record keeping is simplified</li>
                    <li>1% discount in year one</li>
                </ul>

                <h3>Cons</h3>
                <ul>
                    <li>You&apos;ll be ineligible to claim VAT back on purchases</li>
                    <li>Most tax-exempt income is taxed at a flat rate (property, subscriptions etc)</li>
                    <li>Business Classification to determine the flat rate</li>
                    <li>Limited Cost Businesses are charged at a higher rate</li>
                </ul>

                <br />

                <h2>In Summary</h2>
                <p>
                Overall, the new Flat Rate Scheme seems like something that could help many small businesses in the UK.
                Nevertheless, working out whether you are one of them may require a little extra reading.
                </p>

                <p>
                Should you discover that your business is eligible for a low flat rate and you purchase little to no goods,
                then registration may be beneficial for you. However, if you&apos;re a business that buys and sells lots of products
                or if you&apos;re a limited cost business, then you may be worse off under the scheme.  Equally, if you feel as
                though your business may quickly exceed the £230,000 threshold before the first year is through, then it may
                not be worth your while.  Although, it may still be worth considering if the 1% discount could save you money.
                </p>

                <p>
                Indeed, there is much to consider.  Business owners must carefully consider whether the flat rate VAT scheme is
                suitable for their needs, since some limitations and restrictions that may make it less beneficial for some businesses.
                It is a good idea to discuss the pros and cons of the flat rate VAT scheme for your business with your accountant.
                You may sign up to the scheme by
                <a href="https://www.gov.uk/vat-flat-rate-scheme/join-or-leave-the-scheme"> registering here</a>,
                although do discuss it first with your accountant, as any decent accountant will be happy to perform this step on your behalf.
                </p>
                <br />

            </Col>

            <Col>
                <Advert />
            </Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        <Row>
            <Col>
                <div className="display-ad">
                    <Advert />
                </div>
            </Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        </Container>

        </main>
        <Footer />
    </>
    )
}

export default FlatRateVATScreen
