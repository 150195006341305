import React from 'react'

import './QuickLinksMenu.css'


const QuickLinksMenu = () => {
    return (
    <>
        <section id="QuickLinksMenu">
          <h5 className="mb-4">Quick Links</h5>
          <a className="btn btn-link" href="/">Home</a>
          <a className="btn btn-link" href="/vat-calculator">VAT Calculator</a>

          <a className="btn btn-link" href="/sitemap.xml">XML Sitemap</a>
          <a className="btn btn-link" href="/tos">Terms of Service</a>
          <a className="btn btn-link" href="/privacy">Privacy Policy</a>
        </section>
    </>
    )
}

export default QuickLinksMenu
