import React                            from 'react'
import { Helmet }                       from 'react-helmet'
import { Container, Row, Col, Table }   from 'react-bootstrap'
import { HashLink }                     from 'react-router-hash-link'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Advert from '../components/Advert'

import './CurrentVATRateScreen.css'


const CurrentVATRateScreen = () => {

    const imageDir = `/img`

    return (
    <>
        <Helmet>
            <title>A Guide to UK VAT Rates: Standard, Reduced, and Zero</title>
            <meta name="description" content="Navigate the complexities of UK VAT rates with this comprehensive guide. Delve into the distinctions between Standard, Reduced, and Zero rated VAT calculations." />
        </Helmet>

        <Header />
        <main className='py-3'>

        <Container id="CurrentVATRateScreen" className="copy">
        <Row>
            <Col>
                <Advert />
            </Col>

            <Col xs={8}>
                <div className="article" published="2023-04-19T12:33:00+00:00" updated="2023-04-19T12:33:00+00:00">

                    <section id="introduction">
                        <h1 className="display-4">A Guide to UK VAT Rates: Standard, Reduced, and Zero</h1>

                        <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                            <img src={`${imageDir}/current-vat-rate.jpg`} alt="A Guide to UK VAT Rates" />
                        </p>

                        <h2>Introduction</h2>
                        <p>
                        All UK businesses must understand their tax obligations.  VAT is a tax on goods
                        and services, levied at the point of consumption, or so goes the theory.
                        As a business owner, you&apos;ll need to appreciate that different VAT rates are applied to
                        different goods and services and in this guide, we&apos;ll explore the three main
                        VAT rates in the UK: standard rate, reduced rate, and zero rate.
                        </p>

                        <p>
                        In learning how these rates are applied, and on which goods and services, you can ensure
                        your business can avoid potential HMRC imposed penalties, by being tax compliant.
                        </p>

                        <h3>In this Article</h3>
                        <ul>
                            <li><HashLink smooth to='#introduction'>Introduction</HashLink></li>
                            <li><HashLink smooth to='#standard-rate-of-vat'>The Standard Rate of VAT</HashLink></li>
                            <li><HashLink smooth to='#reduced-rate-of-vat'>The Reduced Rate of VAT</HashLink></li>
                            <li><HashLink smooth to='#zero-rated-vat'>Zero Rated VAT</HashLink></li>
                            <li><HashLink smooth to='#vat-exemptions'>VAT Exemptions</HashLink></li>
                            <li><HashLink smooth to='#rates-comparison-table'>A Comparison of VAT Rated Goods & Services</HashLink></li>
                            <li><HashLink smooth to='#changes-to-vat-rates'>Changes to VAT Rates</HashLink></li>
                            <li><HashLink smooth to='#conclusion'>Conclusion</HashLink></li>
                        </ul>
                    </section>

                    <section id="snippet" description="The three main VAT rates">
                        <h2>The UK&apos;s Three Main VAT Rates</h2>
                        <p>
                        The UK has three main VAT rates which are applied to goods and services consumed in the UK.
                        </p>

                        <p>
                        <strong>The standard rate of VAT applied to goods and services consumed in the UK is 20%.
                        In addition to the standard rate there are also two reduced rates: reduced rate
                        (5%) and zero rate (0%).</strong>
                        </p>

                        <p>We'll take a look at each of these rates in turn and how they&apos;re applied</p>
                    </section>

                    <section id="standard-rate-of-vat">
                        <h2>The Standard Rate of VAT (20%)</h2>
                        <p>
                        As stated, the standard rate of VAT in the UK applied to most goods and services is 20%, so that
                        is the most businesses and consumers will be familiar with this rate.
                        </p>

                        <p>
                        Some examples of goods and services subject to this standard, 20% rate include:
                        </p>

                        <ul>
                            <li>Electronics, appliances, and home furnishings</li>
                            <li>Non-essential food and drink, including alcoholic beverages and confectionery</li>
                        </ul>
                    </section>

                    <section id="reduced-rate-of-vat">
                        <h2>The Reduced Rate of VAT (5%)</h2>

                        <p>
                        The reduced VAT rate is set to 5% and applies to specific goods and services considered
                        essential or that provide social benefit.  Some examples of items subject to the reduced
                        rate include:
                        </p>

                        <ul>
                            <li>Child car seats</li>
                            <li>Mobility aids for the elderly</li>
                        </ul>
                    </section>

                    <section id="zero-rated-vat">
                        <h2>Zero Rated VAT (0%)</h2>

                        <p>
                        Zero-rated goods and services are still subject to VAT, but the rate is set at 0%.
                        This means that while you don&apos;t charge VAT on these items, you may still be able to
                        reclaim VAT on any related purchases or expenses.
                        </p>

                        <p>Some examples of zero-rated goods and services include</p>
                        <ul>
                            <li>Children&apos;s clothing and footwear</li>
                            <li>Books, newspapers, and magazines</li>
                        </ul>
                    </section>

                    <section id="vat-exemptions">
                        <h2>VAT Exemptions</h2>

                        <p>
                        In addition to the zero rated category, there&apos;s also the VAT exempt category, and goods and services
                        in this category are not subject to VAT taxation.  The caveat is that on zero rated items, you may reclaim
                        VAT on purchases, while on VAT exempt items, you may not.
                        </p>

                        <p>Some VAT exempt goods & services include:</p>

                        <ul>
                            <li>Financial and insurance services</li>
                            <li>Some cultural services, such as admission to museums</li>
                        </ul>
                    </section>

                    <section id="rates-comparison-table">
                        <h2>A Comparison of VAT Rated Goods & Services</h2>
                        <p>
                        The following table lists examples of different types of goods and services and which VAT rate they belong in.
                        </p>
                        <div id="VATItemsTable">
                            <Table id="tblVATItems" striped bordered hover>
                                <thead>
                                    <tr>
                                        <td>Standard Rate VAT Items</td>
                                        <td>Reduced Rate VAT Items</td>
                                        <td>Zero Rated VAT Items</td>
                                        <td>VAT Exempt Items</td>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Electronics & Appliances</td>
                                        <td>Domestic Fuel & Power</td>
                                        <td>Fresh Produce, Cold Takeaway Food</td>
                                        <td>Financial and insurance services</td>
                                    </tr>

                                    <tr>
                                        <td>Clothing and footwear (excluding some exceptions)</td>
                                        <td>Children&apos;s car seats</td>
                                        <td>Books, newspapers</td>
                                        <td>Health services provided by registered health professionals (doctors, dentists, opticians)</td>
                                    </tr>

                                    <tr>
                                        <td>Household Furniture & Appliances</td>
                                        <td>Home adaptations for disabled people</td>
                                        <td>Medical Equipment & Aids</td>
                                        <td>Education</td>
                                    </tr>

                                    <tr>
                                        <td>Most professional services, eg. legal, accounting</td>
                                        <td>Residential Energy-Saving Materials</td>
                                        <td>Goods & Services exported outside the EU</td>
                                        <td>Some cultural services, such as admission to museums, galleries, and zoos</td>
                                    </tr>

                                    <tr>
                                        <td>Fuel (excluding domestic fuel and power)</td>
                                        <td>Smoking cessation products (nicotine patches etc)</td>
                                        <td>Prescription medications, some medical equipment</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </section>

                    <section id="changes-to-vat-rates">
                        <h2>Changes to the UK VAT Rates</h2>

                        <p>
                        In the UK, the rates of VAT are set in legislation so the government may only change these rates by
                        changing legislation, a process which is subject to Parliamentary scrutiny and approval.
                        In light of this, it is important for businesses to remain informed on any changes to VAT rates,
                        since they must be passed onto the end consumer.
                        </p>

                        <p>
                        The UK VAT rates have only been amended a handful of times since its introduction in 1973.
                        </p>
                    </section>

                    <section id="conclusion">
                        <h2>In Summary</h2>

                        <p>
                        In this short article, we&apos;ve introduced the three main VAT rates applied to goods and services
                        consumed in the UK.  We&apos;ve also highlighted the difference between zero rated and VAT exempt category
                        goods and services, an important distinction.
                        </p>
                    </section>
                </div>

            </Col>

            <Col>
                <Advert />
            </Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        <Row>
            <Col>
                <Advert />
            </Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        </Container>

        </main>
        <Footer />
    </>
    )
}

export default CurrentVATRateScreen

