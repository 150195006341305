import React from 'react'
import { Container, Row, Col }  from 'react-bootstrap'
import { HashLink }             from 'react-router-hash-link'
import { Helmet }               from 'react-helmet'

import Header               from '../components/Header'
import Footer               from '../components/Footer'
import Advert               from '../components/Advert'

import './AnnualAccountingVATScreen.css'


const AnnualAccountingVATScreen = () => {

    const imageDir = `/img`

    return (
    <>
        <Helmet>
            <title>Annual Accounting VAT</title>
            <meta name="description" content="CalcVAT.co.uk article: The Annual Accounting VAT Scheme" />
        </Helmet>

        <Header />

        <main>

        <Container id="AnnualAccountingVATScreen" className="copy">

        <Row>
            <Col>
                <Advert />
            </Col>

            <Col xs={8}>

                <section id="heading">
                    <h1 className="display-4 fw-normal">The Annual Accounting VAT Scheme</h1>

                    <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                        <img src={`${imageDir}/annual-vat.jpg`} alt="Annual Accounting VAT Scheme" />
                    </p>
                </section>

                <section id="intro">
                    <p>
                    We&apos;ve covered in a previous article the flat rate accounting scheme, which is beneficial to startups and
                     small business.  There are advantages too to the Annual Accounting VAT Scheme which will also be of interest
                     to these business types.  There&apos;s some great news too about both schemes that means you won&apos;t have to
                     choose one over the other.  Armed with this knowledge, you can have an informed discussion about your VAT scheme
                     with your accountant to choose which best suits your business.  Read on.
                    </p>

                    <p>
                    This article lays out an executive summary of the Annual Accounting VAT Scheme and how it is advantageous to businesses.
                     Here are some quick links to the main points.
                    </p>

                    <ul>
                        <li><HashLink smooth to='#how-the-scheme-works'>How the Annual Accounting Scheme Works</HashLink></li>
                        <li><HashLink smooth to='#advantages'>Advantages of the Annual Accounting Scheme</HashLink></li>
                        <li><HashLink smooth to='#eligibility'>Eligibility for the Annual Accounting VAT Scheme</HashLink></li>
                        <li><HashLink smooth to='#instalments'>Paying Instalments</HashLink></li>
                        <li><HashLink smooth to='#instalments-examples'>Simple Examples of Paying Instalments</HashLink></li>
                        <li><HashLink smooth to='#combining-schemes'>Combining Annual Accounting & Flat Rate Schemes</HashLink></li>

                        <li><HashLink smooth to='#applying'>How to Apply for the Combined Annual and Flat Rate Schemes</HashLink></li>
                        <li><HashLink smooth to='#withdrawing'>How to Withdraw from the Annual VAT Scheme</HashLink></li>
                        <li><HashLink smooth to='#summary'>Summary</HashLink></li>
                    </ul>
                </section>

                <section id="how-the-annual-accounting-scheme-works">
                    <h2>How the Annual Accounting Scheme Works</h2>

                    <p>
                    The annual accounting scheme allows a business to complete just one VAT return for the year period, instead of four.  Instead of one large payment for VAT, you'll pay instalments of the VAT you expect to owe.
                    </p>

                    <p>
                    You may choose to make either three quarterly or nine monthly instalment payments, to spread the cost of your VAT liability throughout the year period.  These payments must be made via Direct Debit, Standing Order or other electronic means.
                    </p>
                </section>

                <section id="advantages">
                    <h2>Advantages of the Annual Accounting Scheme</h2>
                    <ul>
                        <li>Reduced reporting burden (Just one VAT return per year instead of four).</li>
                        <li>Allows the business to make payments at a set amount, either monthly or quarterly, towards their total VAT bill for the year.  Additional payments may be made adhoc, as it suits the business.  You then have two months (instead of one) to submit your annual VAT return and pay the remaining balance due.</li>
                        <li>Aids cash flow management (via forecasting)</li>
                        <li>Can be advantageous for businesses whose revenue is seasonally dependent (eg. retailers get a surge at xmas).</li>
                        <li>Can be used in combination with other VAT schemes, eg. the flat rate VAT scheme.</li>
                    </ul>
                </section>

                <section id="eligibility">
                    <h2>Eligibility for the Annual Accounting VAT Scheme</h2>
                    <p>
                    In order to qualify for the annual accounting scheme, your company must have "taxable supplies" of £1.35M or less.  HMRC likes to deploy  terminology to confuse the reader, but this just means your turnover (sales revenue), excluding VAT.
                    </p>

                    <p>You'll be able to stay in the annual VAT scheme unless your taxable turnover exceeds £1.6M per year.</p>

                    <p>
                    When you register for VAT you'll make an estimate of your expected earnings for the year, which may be based on the previous 12 months, or your estimate may be based on your business plan or information from a previous business owner.
                    </p>

                    <p>
                    If you realise during the year that you'll exceed the £1.6M limit on the annual accounting scheme, you must inform the HMRC immediately, and  you'll be withdrawn from the accounting scheme.
                    </p>

                    <p>
                    So long as there's reasonable grounds for your estimate, you won't be penalised if you go over the £1.6M limit, so it's important to ensure you keep a record of how you arrived at the estimation.
                    </p>

                    <p>
                    There are also some factors that will exclude your business from eligibility from the annual accounting scheme.
                    </p>

                    <p>
                    You may not join the scheme if your business:
                    </p>

                    <ul>
                        <li>Is VAT registered as part of a group or division of a company</li>
                        <li>Has stopped using annual accounting in the last 12 months</li>
                        <li>Is insolvent</li>
                        <li>Has a VAT debt that is getting bigger.</li>
                    </ul>

                    <p>
                    You may be allowed to use the scheme if your have a small VAT debt and have agreed proposals to clear that debt with HMRC.
                    </p>
                </section>

                <section id="instalments">
                    <h2>Paying Instalments</h2>
                    <p>
                    You may elect to pay either three quarterly installments, or nine monthly installments, with a two month break before having to submit your VAT return and pay the remaining balance of your VAT owed.
                    </p>

                    <p>
                    Under normal conditions, you'll pay nine instalments of 10% of your last year's annual VAT bill, or, if you've applied for quarterly instalments instead, for each quarterly instalment you'll pay 25% of your expected VAT bill.
                    </p>

                    <table id="tblInstalments">
                    <thead>
                        <tr bgColor="#ffffe0">
                            <td colSpan="15">
                            Payments Due
                            </td>
                        </tr>

                        <tr bgColor="#ffe0e0">
                            <td>Months of Period</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>
                            <td>7</td>
                            <td>8</td>
                            <td>9</td>
                            <td>10</td>
                            <td>11</td>
                            <td>12</td>
                            <td>13(1)</td>
                            <td>14(2)</td>
                        </tr>
                    </thead>

                    <tbody bgColor="#e0e0e0">
                        <tr>
                        <td>Monthly Payments</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>1st</td>
                        <td>2nd</td>
                        <td>3rd</td>
                        <td>4th</td>
                        <td>5th</td>
                        <td>6th</td>
                        <td>7th</td>
                        <td>8th</td>
                        <td>9th</td>
                        <td></td>
                        <td>Final Payment</td>
                        </tr>

                        <tr>
                        <td>Quarterly Payments</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>1st</td>
                        <td></td>
                        <td></td>
                        <td>2nd</td>
                        <td></td>
                        <td></td>
                        <td>3rd</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Final Payment</td>
                        </tr>
                    </tbody>
                    </table>
                </section>

                <section id="instalments-examples">
                    <h2>Simple Examples of Paying Instalments</h2>

                    <p>
                    If your previous year's VAT bill was £10K, you could expect to pay 9 monthly payments of £1K or three quarterly payments of £2,500, with a final payment being equal to your remaining VAT liability for the annual period.
                    </p>

                    <h3>The Annual Accounting Registration Unit</h3>
                    <p>
                    You would expect to pay these instalments if your previous 12 month period had a £10k VAT bill.  But if you expect your VAT bill to be significantly different from the previous 12 month period, you may contact the Annual Accounting Registration Unit and explain your case.  In the case that the team agrees with your forecast, they will revise your instalments up or down as necessary.
                    </p>

                    <p>
                    You might also contact this team if there is a significant change in the circumstances of your business.  This might be for example if you expect that your turnover will exceed £1.6M for the annual period.
                    </p>

                    <p>
                    The HMRC can be contacted online or via telephone.  Their contact details for general VAT enquiries are found on <a href="https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-enquiries">this HMRC enquiries page</a>.
                    </p>
                </section>

                <section id="combining-schemes">
                    <h2>Combining Annual Accounting & Flat Rate Schemes</h2>
                    <p>
                    You may apply to use the annual accounting scheme in combination with the flat rate accounting scheme.  You may instead opt to combine the annual accounting scheme with other schemes, like the cash accounting scheme or retail schemes for example.  We'll cover these schemes in a later article.
                    </p>

                    <p>
                    Combining accounting schemes can make a significant difference to the cost of your VAT bill along with reducing the burden of monthly VAT reporting.  Discuss your options with your accountant if you're unsure about which scheme(s) suit your business best.
                    </p>
                </section>

                <section id="applying">
                    <h2>How to Apply for the Combined Annual and Flat Rate Schemes</h2>
                    <p>
                    Applying for the Annual Accounting and Flat Rate VAT schemes is a matter of paperwork, which your accountant may complete on your behalf.  If you wish to do so directly, here's a link to <a href="https://www.gov.uk/government/publications/vat-application-to-join-the-annual-accounting-and-flat-rate-scheme-vat600aafrs">the application document</a>.  The form you need is called form VAT600AA/FRS.
                    </p>
                </section>

                <section id="withdrawing">
                    <h2>How to Withdraw from Annual Accounting</h2>
                    <p>
                    If you wish to withdraw from the Annual Accounting Scheme, you just need to contact the Annual Accounting Registration Unit and tell them so.  You'll return to the normal method of paying and accounting for your VAT liability, but you'll still get two months to submit your final VAT return along with any remaining payment balance.
                    </p>
                </section>

                <section id="summary">
                    <h2>In Summary</h2>
                    <p>
                    The Annual Accounting VAT scheme is a great tool for business owners, since it allows them to really take control of their cashflow by enabling them to manage their VAT bill.  Breaking VAT payments into manageable chunks that can be paid on a monthly or quarterly basis and reducing reporting burden, the Annual Accounting VAT scheme can also be combined with other accounting schemes like the flat rate scheme, which helps reduce VAT liability.  What's not to like?
                    </p>
                </section>

            </Col>

            <Col>
                <Advert />
            </Col>
        </Row>

        </Container>
        </main>
        <Footer />
    </>
    )
}

export default AnnualAccountingVATScreen
