import React from 'react'


const CompanyAddress = () => {

    const address = process.env.REACT_APP_COMPANY_ADDRESS ||
        "Dramallama Limited, Unit 55 Mallusk Enterprise Park, Newtownabbey, Co. Antrim, BT36 4GN, UK";

    const emailAddress  = process.env.REACT_APP_COMPANY_EMAIL || "enquiries [at] dramallama [dot] ltd";
    const telephone     = process.env.REACT_APP_COMPANY_TELEPHONE;


    return (
        <>
        <h5 className="mb-4">Get In Touch</h5>

        <ul id="company-address">
            {
                address.split(",").map((ln, idx) => {
                    return (<li key={idx}>{ ln }</li>);
                })
            }
        </ul>

        <p id="email-address"><i className="bi bi-envelope me-3"></i>{ emailAddress }</p>

        {
            telephone &&
            <p id="telephone"><i className="bi bi-phone me-3"></i>{ telephone }</p>
        }

        </>
    )
}

export default CompanyAddress
