import React                from 'react'
import { Helmet }           from 'react-helmet'

import { Container, Row, Col }  from 'react-bootstrap'
// import { HashLink }             from 'react-router-hash-link'
import Header               from '../components/Header'
import Footer               from '../components/Footer'
import Advert               from '../components/Advert'

import CompanyAddress       from '../components/CompanyAddress'
import SocialMediaLinks     from '../components/SocialMediaLinks'

import './ContactUsScreen.css'



const ContactUsScreen = () => {

    const imageDir = `/img`

    return (
    <>
        <Helmet>
            <title>Contact Us</title>
            <meta name="description" content="Our Contacts Page.  Find  our company address and our social media presence here!" />
        </Helmet>

        <Header />

        <main className='py-3'>

        <Container id="ContactUsScreen" className="copy">

        <Row>
            <Col>
                <Advert />
            </Col>

            <Col xs={8}>

                <section id="heading">
                    <h1 className="display-4 fw-normal">Contact Us</h1>

                    <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                        <img src={`${imageDir}/contact-us.jpg`} alt="contact us" />
                    </p>
                </section>

                <section id="contact-us">

                    <Container id="contact-details">
                    <Row>
                        <Col className="col-6">
                            <CompanyAddress />
                        </Col>

                        <Col className="col-6">
                            <SocialMediaLinks />
                        </Col>
                    </Row>
                    </Container>
                </section>
            </Col>

            <Col>
                <Advert />
            </Col>
        </Row>

        </Container>
        </main>
        <Footer />
    </>
    )
}

export default ContactUsScreen
