import React, { useState }  from 'react'

import useStore from '../state/GlobalState'

import './VatToggleCtrl.css'

const VatToggleCtrl = (props) => {

    const {enabledLabel, disabledLabel} = props;

    const vatToggle    = useStore((state) => state.vatToggle);
    const setVatToggle = useStore((state) => state.setVatToggle);

    const [checked, setChecked]     = useState(vatToggle);
    const [labelText, setLabelText] = useState(enabledLabel);

    const handleChange = (event) => {
        setVatToggle(event.target.checked);
        setChecked (event.target.checked);
        setLabelText(event.target.checked ? enabledLabel : disabledLabel);
    };

    return (
    <>
        <div id="VatToggleCtrl">

            <label>{labelText}</label>
            <label className="VatToggle">
                <input
                    type="checkbox"
                    onChange={handleChange}
                    checked={checked}
                    defaultValue={checked}
                />
                <span className="slider"></span>
            </label>
        </div>
    </>
    )
}

export default VatToggleCtrl
