import React                    from 'react'
import { Container, Row, Col }  from 'react-bootstrap'
import { Helmet }               from 'react-helmet'

import { HashLink }             from 'react-router-hash-link'

import Header                   from '../components/Header'
import Footer                   from '../components/Footer'
import Advert                   from '../components/Advert'

import './RegisterForVatScreen.css'

const RegisterForVatScreen = () => {

    const imageDir = `/img`

    return (
    <>

        <Helmet>
            <title>How to Register for VAT in the UK</title>
            <meta name="description" content="Read our comprehensive guide on UK VAT registration. Understand the process, requirements, and key considerations to ensure a compliant VAT registration." />
        </Helmet>

        <Header />
        <main className='py-3'>

        <Container id="RegisterForVatScreen" className="copy">
        <Row>
            <Col>
                <Advert />
            </Col>

            <Col xs={8}>

                <h1 className="display-4 fw-normal">How to Register for VAT in the UK</h1>

                <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                    <img src={`${imageDir}/register-for-vat-uk.jpg`} alt="Register for VAT in the UK" />
                </p>

                <p>
                There are lots of challenges involved in starting up a new business, but thankfully registering
                for VAT is quick, and once you&apos;ve read this article it&apos;s easy too!  While registration is
                free of charge, it is recommended that you consult with an accountant.  Read on to find out why.
                </p>

                <section id="snippet" description="quick-answer-how-to-register-for-vat">

                    <h2>Registering for VAT at a Glance</h2>
                    <p>
                    It is recommended that you discuss registering for VAT with your accountant before doing so.  Your business may not meet the
                    turnover threshold to require registration.  Your accountant may also recommend government schemes which may save your business
                    money.  They may even take care of the task of registering on your behalf.
                    </p>

                    <p>
                    There are two ways to register for VAT in the UK.
                    You may <a href="https://www.access.service.gov.uk/registration/email">register</a> online for a government gateway id,
                    then once you've got that, find the <i>Get another tax, duty or scheme</i> section and follow the registration instructions there.
                    </p>

                    <p>
                    If you prefer the pen and paper method, you should fill in and return &nbsp;
                    <a href="https://www.gov.uk/guidance/register-for-vat">the VAT1 form</a> to HMRC by post.
                    </p>
                </section>


                <section id="introduction">
                    <h2>What&apos;s in this Article</h2>
                    <p>
                    To make things as clear as possible, we wanted to provide you with all the information you might need for
                    registering your business for VAT in the UK in 2023.
                    </p>

                    <ul>
                        <li> <HashLink smooth to='#when-should-you-register-for-vat'>When you should register for VAT</HashLink> </li>
                        <li> <HashLink smooth to='#how-do-you-register-for-vat'>How Do You Register For VAT?</HashLink> </li>
                        <li> <HashLink smooth to='#what-happens-once-you-register'>What happens once you&apos;ve registered for VAT?</HashLink> </li>
                        <li> <HashLink smooth to='#how-much-is-vat-in-the-uk-2023'>How much is the VAT rate UK 2023</HashLink> </li>
                        <li> <HashLink smooth to='#what-vat-schemes-are-available'>What VAT schemes are available</HashLink> </li>
                    </ul>
                </section>

                <section id="when-should-you-register-for-vat">

                    <h2>When should you Register for VAT?</h2>

                    <p>
                    Every business in the UK, whose annual profits are over the 2023 VAT threshold of £85,000, must be registered to
                    pay VAT. This is a legal requirement, and those who do not comply are liable to pay large fines and other consequences
                    which might negatively impact your business. You should also consider registering if you expect profits to exceed this
                    amount within the next 30 days.
                    </p>

                    <p>
                    In fact, many people voluntarily register for VAT before meeting the compulsory threshold. This is done for any
                    number of reasons, such as to reclaim VAT on purchases made (up to 4 years prior the date of registration), or
                    appearing as a more established business by providing VAT details on invoices, receipts and emails.
                    </p>

                    <p>
                    Some people voluntarily register for VAT early simply because it is one thing checked off the list - avoiding
                    potential fines if they forget once the business exceeds the compulsory profit threshold. Some even state that
                    it helps to get into the swing of bookkeeping and managing cash flow before handling larger figures.
                    </p>

                    <p>
                    Indeed, reasons for early registration are varied. Whether you chose to do it before or after the compulsory
                    threshold, the way you register remains the same.
                    </p>

                    <p>
                    <h3>*TOP TIP*</h3>
                    If you and/or your business reside outside the UK but sell goods or services within the UK, you must still
                    register for UK VAT.
                    </p>
                </section>

                <section id="how-do-you-register-for-vat">
                    <h2>How Do You Register For VAT?</h2>

                    <p>
                    Registration for VAT is simple and can be done in one of two ways:
                    </p>

                    <h3>Method 1: Online Registration</h3>
                    <p>
                    This firstly requires a Government Gateway ID which you can create on the &nbsp;
                    <a href="https://www.access.service.gov.uk/login/signin/creds">Government Gateway</a> - you will
                    need to provide your full name, email address and password in order to do so.  Once you gain access,
                    click on the 'Get another tax, duty or scheme' section. Here you will find instructions to register
                    your business for VAT.
                    </p>

                    <p>
                    The registration process will require your National Insurance (NI) number or Unique Taxpayer Reference (UTR),
                    details of businesses you&apos;ve owned previously and your business bank account details so have them at the
                    ready!
                    </p>

                    <p>
                    You should soon receive confirmation of registration and a 9-digit VAT number. At which point you
                    can start using VAT for your business.
                    </p>

                    <p>
                    Remember, your accountant can handle the registration process on your behalf and its worthwhile discussing VAT registration
                    with your accountant, as they can recommend VAT schemes that may be applicable to your business.
                    </p>


                    <h3>Method 2: Complete Form VAT1 and post it to HMRC</h3>
                    <Row>
                        <Col>
                        <p>
                        You can download the VAT1 form from the HMRC portal <a href="https://www.gov.uk/guidance/register-for-vat">here</a>.
                        Once downloaded, print and fill out your details.  Please read this page carefully, as you may have more than one
                        form to complete.  For this, you are required to provide your National Insurance (NI) number or Unique Taxpayer Reference (UTR),
                        details of businesses you&apos;ve owned previously and your business bank account details, so have them at the ready!
                        </p>

                        <p>
                        Also on this HMRC portal, there is a document that contains notes on how to fill in the VAT1 form, which you may find useful,
                        which is linked opposite.
                        </p>

                        <p>
                        Once completed, send all forms and any additional documents to HMRC using the address provided at the bottom of
                         the VAT1 form. You should expect to wait up to 30 days to receive confirmation of registration. At which point
                         you must charge VAT for your business.
                        </p>
                        </Col>

                        <Col>
                        <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                            <a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1092853/VAT1-07-22.pdf">
                                <img src={`${imageDir}/vat1-form.jpg`} alt="register for VAT by post with the vat1 form" />
                            </a>
                        </p>

                        <p style={{marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em'}}>
                            <a href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1100901/VAT1-Notes_English.pdf">
                                <img src={`${imageDir}/vat1-help.jpg`} alt="notes to help complete the VAT1 form" />
                            </a>
                        </p>
                        </Col>
                    </Row>
                </section>

                <section id="what-happens-once-you-register">
                    <h2>What Happens Once You&apos;ve Registered for VAT in the UK?</h2>

                    <p>
                    Once registered, you&apos;ll receive:
                    </p>
                    <ul>
                        <li>Confirmation of registration</li>
                        <li>An Effective date of Registration</li>
                        <li>A unique, 9-digit VAT number (which must be used on all VAT transactions)</li>
                    </ul>

                    <p>
                    Now you have received the items listed above, you are able to start charging VAT on all
                    goods and services sold.
                    </p>

                    <p>
                    You may also claim any VAT on goods and services bought for your business. You can do this
                    from the 'Effective Date of Registration'.
                    </p>

                    <p>
                    Additionally, it is worth seeing if you can reclaim on any purchases made for your business
                    prior to registering. This can be done on any goods or services bought within 4 years of registering.
                    All purchases must relate to your businesses purpose (the VAT taxable goods and services you provide.)
                    You will always need to provide receipts for any reclaims.
                    </p>

                    <p>
                    <h3>*TOP TIP*</h3>
                    All registered businesses must now use Making Tax Digital (MTD) for your VAT accounting. This
                    means all record keeping and tax returns must be done via MTD compatible software which can be
                    found <a href="https://www.tax.service.gov.uk/making-tax-digital-software">here</a>.
                    </p>
                </section>

                <section id="how-much-is-vat-in-the-uk-2023">
                    <h2>How Much Is VAT In The UK in 2023?</h2>
                    <p>
                    Please check out our full article on &nbsp;
                    <HashLink smooth to='/current-vat-rate#snippet'>the current rates of VAT in the UK</HashLink>.
                    You must register for and charge VAT on all your sales if your turnover meets a threshold of £85,000 pounds in any
                    rolling twelve month period.
                    </p>

                    <p>
                    Once your business is registered for VAT, using your unique 9-digit VAT number you must
                    charge the standard rate of 20% on all goods and services sold. You also pay 20% VAT on goods
                    and services you buy for your business.  However, because you are now VAT registered this can be
                    reclaimed when you send your quarterly tax returns.
                    </p>

                    <p>
                    Your business may be eligible for government schemes that could save you money and help manage cash flow.
                    This may mean that you only need to collect a reduced rate of VAT to help your business grow.  Ask your
                    accountant about the flat rate vat schemes available.
                    </p>
                </section>

                <section id="what-vat-schemes-are-available">
                    <h2>What VAT Schemes are Available?</h2>
                    <p>
                    The world of tax can be a daunting place for new businesses. That&apos;s why there are a few schemes
                    available to help you on your way.
                    </p>

                    <ul>
                        <li>
                        <HashLink smooth to='/flat-rate-vat#snippet'><strong>Flat Rate VAT Scheme</strong></HashLink>
                        &nbsp;- Businesses with an annual profit under the £150,000 threshold may apply
                        for the flat rate scheme. This allows you to pay a fixed percentage which is often lower than the
                        20% standard rate.
                        </li>

                        <li>
                        <strong>Annual Accounting Scheme</strong> - Any business with an annual profit under a £1.35 million threshold can
                        apply for the annual accounting scheme. This allows a business to send one tax return per year
                        whilst paying advanced quarterly instalments.
                        This may also be combined with the <HashLink smooth to='/flat-rate-vat#snippet'><strong>Flat Rate VAT Scheme</strong></HashLink>.
                        </li>

                        <li>
                        <strong>Cash Accounting Scheme</strong> - Any business with an annual profit under £1.35 million threshold can apply
                        for the cash accounting scheme. This allows income to be recorded when it is received and expenses
                        recorded in the period they are paid, rather than before.
                        </li>
                    </ul>
                </section>

            </Col>

            <Col>
                <Advert />
            </Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        <Row>
            <Col>
                <div className="display-ad">
                    <div id="ezoic-pub-ad-placeholder-104"> </div>
                </div>
            </Col>
        </Row>

        <Row><Col style={{height: '4em'}}></Col></Row>

        </Container>

        </main>
        <Footer />
    </>
    )
}

export default RegisterForVatScreen
