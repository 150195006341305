import React from 'react'
import CookieConsent from 'react-cookie-consent'


const CookieBanner = () => {
    return (
    <>
    <CookieConsent
          location="bottom"
          buttonText="Agree"
          cookieName="cookieConsent"
          style={{ background: "#2B373B", textAlign: "center" }}
          buttonStyle={{ color: "#4e503b", fontSize: "12px" }}
          expires={150}
    >
          This website uses cookies to enhance the user experience.
          <div style={{ fontSize: "10px" }}>Your use of this site grants consent to use cookies</div>
        </CookieConsent>
    </>
    )
}

export default CookieBanner
