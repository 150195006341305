import React, { useState, useRef } from 'react'
import useStore from '../state/GlobalState'

import './VatRateCtrl.css'

const VatRateCtrl = () => {

    const vatRate    = useStore((state) => state.vatRate);
    const setVatRate = useStore((state) => state.setVatRate);

    const [value, setValue]         = useState(vatRate);
    const [changing, setChanging]   = useState(false);
    const timeoutId                 = useRef(null);

    const handleChange = (event) => {

        if (changing)
            return;

        setChanging(true);

        setVatRate(event.target.value);
        setValue(event.target.value);

        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }

        timeoutId.current = setTimeout(() => {
          setChanging(false);
        }, 250);
    };

    return (
    <>
        <div id="VatRateCtrl">
        <label>VAT Rate: {value} %</label>
        <label>
            <input
              id="VatRateCtrlInput"
              type="range"
              min="0"
              max="25"
              step="0.25"
              defaultValue={value}
              onChange={handleChange}
            />
        </label>
        </div>
    </>
    )
}

export default VatRateCtrl
